import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom";
import { Collapse, Button, Skeleton, Popconfirm, Drawer, List, Popover } from 'antd';

import NewProject from './NewProject.component'

import { ProjectContext } from '../util/ProjectContext'

const { Panel } = Collapse;

const btnStyle = { margin: "5px", width: "150px" };

export default function ExistingProjects(props) {
    const history = useHistory();
    const [dVisible, setDVisible] = useState(false);
    const { reloadCProject } = useContext(ProjectContext);

    const [currentProjectId, setCurrentProjectId] = useState(null);

    const navigate = async (p, to, type) => {
        await reloadCProject(p.id);

        if (to === 'result') {
            history.push(`projects/${p.id}/${type}`)
            return;
        } else if (to === 'browse') {
            history.push(`/projects/${p.id}/browse`)
            return;
        }

        switch (p.status) {
            case "INIT":
                history.push(`projects/${p.id}/upload`)
                break;
            case "IN-PROGRESS":
                history.push(`projects/${p.id}/view`)
                break;
            case "DONE":
                history.push(`projects/${p.id}/view`)
                break;
            default:
                history.push(`projects/${p.id}/upload`)
                break;
        }
    }

    const resultsListContent = (p) => <List size="small">
        <List.Item style={{ padding: 0 }}><Button type="link" onClick={() => navigate(p, 'result', 'annotate')}>Annotation Results</Button></List.Item>
        <List.Item style={{ padding: 0 }}><Button type="link" onClick={() => navigate(p, 'result', 'relations')}>Relationships Results</Button></List.Item>
        <List.Item style={{ padding: 0 }}><Button type="link" onClick={() => navigate(p, 'result', 'segments')}>Segments Results</Button></List.Item>
    </List>;

    const spinner = <Skeleton active paragraph={{ rows: 7 }} />;
    const ProjectListPanel = () =>
        <Collapse accordion defaultActiveKey={1}>
            {props.projects.map((p) =>
                <Panel header={p.name} key={p.id}>
                    {!!p.description && <p>{p.description}</p>}
                    <Button type="primary" style={btnStyle} onClick={() => { navigate(p) }}>Open Project</Button><br />
                    <Popover content={resultsListContent(p)} overlayClassName="view-results-popover" title="Select Results Type" trigger="click" >
                        <Button style={btnStyle}>View Results</Button><br />
                    </Popover>
                    <Button style={btnStyle} onClick={() => { navigate(p, 'browse') }}>Browse Project</Button><br />
                    <Button style={btnStyle} onClick={() => { setCurrentProjectId(p.id); setDVisible(true); }}>Clone Project</Button><br />
                    <Popconfirm title="Are You sure you want to delete this project?" onConfirm={() => props.onRemove && props.onRemove({ id: p.id })}> <Button danger style={btnStyle} >Delete Project</Button></Popconfirm><br />
                </Panel>)}
        </Collapse>;

    return (
        <>
            <Drawer
                title="Clone Project"
                width={500}
                onClose={() => { setDVisible(false); }}
                open={dVisible}
                bodyStyle={{ padding: "24px" }}
            >
                <NewProject onSubmit={(data) => { props.onClone && props.onClone({ id: currentProjectId, ...data }); setDVisible(false); }} isClone />
            </Drawer>

            {!props.projects && spinner}
            {(props.projects && props.projects.length > 0) && <ProjectListPanel projects={props.projects} />}
            {(props.projects && !props.projects.length) && "No Projects available!"}

        </>
    )
};

ExistingProjects.propTypes = {
    onRemove: PropTypes.func,
    onClone: PropTypes.func,
    projects: PropTypes.arrayOf(Object),
}

ExistingProjects.defaultProps = {
    projects: null
}