import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Divider, Table, Empty, Tooltip } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { useLazyEffect } from '../../util/lazy-effect.hook';
import { nerTags } from '../../data/constansts';

import ValidationBar from '../Common/ValidationBar.component';

// eslint-disable-next-line
let prevAnn = -1;

const InlineValidation = (props) => {
    const [currentAnn, setCurrentAnn] = useState(-1);
    const [currentPage, setCurrentPage] = useState(1);

    const columns = [{
        dataIndex: 'html',
        key: 'html',
        render: v => <div className="rich-text-box" dangerouslySetInnerHTML={{ __html: v }}></div>,
    }];

    useLazyEffect(() => {
        if (props.annList?.length) {
            setCurrentAnn(0);
        }
    }, [props.segmentsList, props.segmentsList[0]?.id], currentPage);

    useLazyEffect(() => {
        const nAnn = props.annList[currentAnn];
        //const pAnn = props.annList[prevAnn];

        const els = document.getElementsByClassName('active-ann');
        for (let i = 0; i < els.length; i++)
            els[i].classList.remove('active-ann');

        if (nAnn) {
            const el = document.getElementById(nAnn.id);
            if (el) {
                el.classList.add('active-ann');
                el.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
            }
        }
        // if (pAnn) {
        //     const el = document.getElementById(pAnn.id);
        //     if (el) {
        //         el.classList.remove('active-ann');
        //     }
        // }
    }, [currentAnn, props.annList[currentAnn]]);

    const onTableChange = (pagination, filters, sorter) => {
        if (props.handelGridChange) props.handelGridChange(pagination, filters, sorter);
        setCurrentPage(pagination.current);
    }

    const handleKeyDown = (e) => {
        if (e.altKey || e.ctrlKey || e.shiftKey)
            return;

        const ann = props.annList[currentAnn];
        if (!ann?.id) return;
        switch (e.keyCode) {
            case 39: //next
            case 40: //down
                e.stopPropagation();
                e.preventDefault();
                nextAnnotation();
                break;
            case 37: //prev
            case 38: //up
                e.stopPropagation();
                e.preventDefault();
                prevAnnotation();
                break;
            case 90: //z
                if (ann.validation?.value === "TO-VALIDATE")
                    props.validationHandler(ann.id, "CORRECT-TYPE", currentAnn);
                break;
            case 88: //x
                if (ann.validation?.value === "TO-VALIDATE")
                    props.validationHandler(ann.id, "INCORRECT", currentAnn);
                break;
            case 67: //c
                if (ann.validation?.value === "TO-VALIDATE")
                    props.validationHandler(ann.id, "CORRECT", currentAnn);
                break;
            case 86: //v
                if (ann.validation?.value !== "TO-VALIDATE")
                    props.validationHandler(ann.id, "TO-VALIDATE", currentAnn);
                break;
            default:
                break;
        }
    }

    const nextAnnotation = () => {
        if ((currentAnn + 1) >= props.annList.length) return;
        prevAnn = currentAnn;
        setCurrentAnn(currentAnn + 1);
    }

    const prevAnnotation = () => {
        if ((currentAnn <= 0)) return;
        prevAnn = currentAnn;
        setCurrentAnn(currentAnn - 1);
    }

    const annotation = props.annList[currentAnn] || {};

    return (
        <>
            {
                !!props.segmentsList?.length &&
                <div
                    tabIndex="0"
                    style={{ outline: 0 }}
                    onKeyDown={handleKeyDown} >
                    {!!annotation?.annotationId &&
                        <>
                            <Row gutter={[8, 8]} >
                                <Col span={4}>
                                    <span style={{ wordBreak: "break-all" }}><b>ID:</b> {annotation?.annotationId}</span>
                                </Col>
                                {/* </Row>
                            <Row gutter={[8, 8]} > */}
                                <Col span={20} >
                                    {
                                        !!annotation?.annotaionType && <span>
                                            <b>Annotation Type:</b>&nbsp;
                                            <Tooltip
                                                overlay={<div>{nerTags[annotation?.annotaionType]?.label || ''} <br />
                                                    i.e. {nerTags[annotation?.annotaionType]?.example || ''}
                                                </div>}
                                                overlayStyle={{ maxWidth: 'unset' }}
                                            >
                                                {annotation?.annotaionType}
                                            </Tooltip>
                                        </span>
                                    }
                                </Col>
                                {/* <Col span={4} >
                                    {annotation?.currentIteration?.language &&
                                        <span><b>Language:</b> {annotation?.currentIteration?.language}</span>
                                    }
                                </Col>
                                <Col span={4} >
                                    {annotation?.currentIteration?.syncon &&
                                        <span><b>SynconID:</b> {annotation?.currentIteration?.syncon}</span>
                                    }
                                </Col> */}
                                {/* <Col span={8} style={{ wordBreak: "break-all" }}>
                                    <span><b>URI:</b> {annotation?.currentIteration?.uri}</span>
                                </Col> */}
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={8} >
                                    {!!annotation?.prevIteration && <span><b>Validation {annotation.prevIteration.num}:</b> {annotation.prevIteration.validation}</span>}
                                    <br />
                                    {!!annotation?.currentIteration && <span><b>Validation {annotation.currentIteration.num}:</b> {annotation.currentIteration.validation}</span>}
                                </Col>
                                <Col span={4} >
                                    <span><b>Begin:</b> {annotation.begin}</span>
                                </Col>
                                <Col span={4} >
                                    <span><b>End:</b> {annotation.end}</span>
                                </Col>
                                {/* <Col span={12} >
                                    <span><b>PrefLabels:</b> {annotation.currentIteration?.prefLabels?.join(", ")}</span>
                                </Col> */}
                            </Row>
                        </>
                    }
                    {!annotation?.annotationId && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No annotations in these segments!" />}
                    <Row style={{ paddingTop: "15px" }}>
                        <Col span={12}>
                            {!!annotation?.annotationId &&
                                <ValidationBar
                                    annotationId={annotation.id}
                                    handler={props.validationHandler}
                                    currentAnn={currentAnn}
                                    isEdit={annotation?.validation?.value !== "TO-VALIDATE"} />}
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                            <Button style={{ width: "120px" }} title="Previous annotation (left Key)" onClick={prevAnnotation} disabled={(currentAnn - 1) < 0}><LeftOutlined /> Previous</Button> &nbsp;
                            <Button style={{ width: "120px" }} title="Next annotation (Right Key)" onClick={nextAnnotation} disabled={(currentAnn + 1) >= props.annList.length}>Next <RightOutlined /></Button>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={24}>
                            <Table
                                className="inline-validation-table"
                                showHeader={false}
                                size="small"
                                dataSource={[
                                    {
                                        key: 'html',
                                        html: props.segmentsList.map(e => e.p).join("<br/>"),
                                    }
                                ]}
                                pagination={{
                                    showQuickJumper: true,
                                    position: ["topRight", "bottomRight"],
                                    pageSize: props.annListMeta.pageSize,
                                    total: props.annListMeta.total,
                                    showSizeChanger: true,
                                    current: currentPage
                                }}
                                onChange={onTableChange}
                                columns={columns} />
                        </Col>
                    </Row>
                </div>
            }
            {!props.segmentsList?.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No segments data found!" />}
        </>
    )
};

InlineValidation.propTypes = {
    view: PropTypes.string.isRequired,
    annList: PropTypes.arrayOf(Object),
    annListMeta: PropTypes.instanceOf(Object),
    segmentsList: PropTypes.arrayOf(Object),
    validationHandler: PropTypes.func,
    handelGridChange: PropTypes.func,
}

InlineValidation.defaultProps = {
    annList: [],
    annListMeta: {
        pageSize: 10,
        start: 1,
        total: 0
    },
    segmentsList: [],
    validationHandler: undefined,
    handelGridChange: undefined
}

export default InlineValidation;