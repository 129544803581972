import React, { useEffect, useState, useContext, useCallback } from "react";
import { Row, Col, Card, Button, Skeleton, message, Divider } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import ProjectUpload from "../components/ProjectUpload.component";
import ProjectFilesList from "../components/ProjectFiles.component";
import CartridgesList from "../components/CartridgesList.component";
import { removeFile, getCartridges, updateProject } from "../services/projects.service";
import { startAnnotation } from "../services/annotation.service";
import { ProjectContext } from '../util/ProjectContext'
import useIsMounted from '../util/is-mounted.hook';

const UploadPage = () => {

  const isMounted = useIsMounted();
  const [cartridgesList, setCartridgesList] = useState([]);
  const { cProject, setCProject, reloadCProject } = useContext(ProjectContext);
  const history = useHistory();

  useEffect(() => {
    if (cProject?.id) {
      if (cProject.status !== "INIT") {
        history.push(window.location.pathname.replace("upload", "view"));
        return;
      }
      getCartridgesList();
    }
    // eslint-disable-next-line
  }, [cProject?.id]);

  const getCartridgesList = useCallback(async () => {
    if (cartridgesList.length) return;
    const res = await getCartridges();
    if (isMounted.current) {
      // Preselect first cartridge
      // if (!cProject.cartridges.length) {
      //   updateProject({ ...cProject, cartridges: [res[0]] });
      // }
      setCartridgesList(res);
    }
  }, [cProject?.id]);

  const handelCartridgesChange = async (data) => {
    const tmpPrg = { ...cProject, cartridges: data.map((item) => ({ id: item.id, name: item.name })) };
    setCProject(tmpPrg);
    updateProject({ id: tmpPrg.id, cartridges: tmpPrg.cartridges });
  };

  const handelStartAnnotation = async () => {
    message.loading("Starting Annotation...", 0);
    if (cProject)
      await startAnnotation({ projectId: cProject.id })

    await reloadCProject(); //Reload project before redirect
    history.push(window.location.pathname.replace("upload", "view"));
    message.destroy();
  };

  const handelFilesChange = async (data, action) => {
    switch (action) {
      case "add":
        setCProject({
          ...cProject,
          files: [...cProject.files, data],
        });
        break;
      case "remove":
        message.loading("Deleting file...", 0);
        const res = await removeFile({ projectId: cProject.id, ...data });
        message.destroy();
        if (res)
          setCProject({
            ...cProject,
            files: cProject.files.filter((f) => f.id !== data.id),
          });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {!cProject?.id && <Skeleton active paragraph={{ rows: 7 }} />}
      {Boolean(cProject?.id) &&
        <div>
          <Row>
            <Col span={10}>
              <h2>Upload Documents</h2>
            </Col>
            <Col span={14} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                style={{ marginBottom: "20px" }}
                onClick={handelStartAnnotation}
                disabled={!(cProject.files?.length > 0 && !!cProject.cartridges.length)}
              >
                Start Annotation <RightOutlined />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Card
                title="Upload Files"
                size="small"
                style={{ minHeight: "500px" }}
                bodyStyle={{ padding: "15px 0" }}
                bordered={false}
              >
                <ProjectUpload
                  onFilesChange={handelFilesChange}
                  projectId={cProject.id}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card
                title="Files and Plans"
                size="small"
                style={{ minHeight: "500px" }}
                bodyStyle={{ padding: "15px 0" }}
                bordered={false}
              >
                <div>
                  <ProjectFilesList
                    filesList={cProject.files || []}
                    onFilesChange={handelFilesChange}
                  />
                  <Divider />
                  <CartridgesList
                    cartridges={cartridgesList}
                    selectedCartridges={cProject.cartridges}
                    onCartridgesChange={handelCartridgesChange}
                    isMultiSelect={false}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      }
    </div>
  );
};

export default UploadPage;