import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip } from 'antd';
import {
    CheckOutlined, CloseOutlined, IssuesCloseOutlined,
    EditOutlined, RetweetOutlined
} from '@ant-design/icons';
import { useLazyEffect } from '../../util/lazy-effect.hook';

export default function ValidationBar(props) {
    const [isEditing, setIsEditing] = useState(false);
    useLazyEffect(() => {
        setIsEditing(false);
    }, [props.annotationId]);

    const shape = (props.icons ? "circle" : "");
    if (props.isEdit && !isEditing)
        return (
            <Tooltip title="Edit Validation" >
                <Button
                    className="edit"
                    shape={shape}
                    size={props.size}
                    type="primary"
                    onClick={() => setIsEditing(true)}
                >
                    {(!props.icons ? "Edit" : <EditOutlined />)}
                </Button>
            </Tooltip>
        );
    return (
        <div
            className={"validation-bar " + (props.icons && "icons")}
            style={{ maxWidth: (props.maxWidth || "unset") }}
        >
            <Tooltip title="Correct (C)" >
                <Button
                    className="correct"
                    shape={shape}
                    size={props.size}
                    onClick={() => props.handler(props.annotationId, 'CORRECT', props.currentAnn)}>
                    {(!props.icons ? "Correct" : <CheckOutlined />)}
                </Button>
            </Tooltip>&nbsp;
            <Tooltip title="Correct Type (Z)" >
                <Button
                    className="correct-type"
                    shape={shape}
                    size={props.size}
                    onClick={() => props.handler(props.annotationId, 'CORRECT-TYPE', props.currentAnn)}>
                    {(!props.icons ? "Correct Type" : <IssuesCloseOutlined style={{ fontSize: '1.4em' }} />)}
                </Button></Tooltip>&nbsp;
            <Tooltip title="Incorrect (X)" >
                <Button
                    className="incorrect"
                    shape={shape}
                    size={props.size}
                    onClick={() => props.handler(props.annotationId, 'INCORRECT', props.currentAnn)}>
                    {(!props.icons ? "Incorrect" : <CloseOutlined />)}
                </Button></Tooltip>&nbsp;
            {props.isEdit && <Tooltip title="To Validate (V)" >
                <Button
                    className="to-validate"
                    shape={shape}
                    size={props.size}
                    onClick={() => props.handler(props.annotationId, 'TO-VALIDATE', props.currentAnn)}>
                    {(!props.icons ? "To-validate" : <RetweetOutlined style={{ fontSize: '1.4em' }} />)}
                </Button></Tooltip>}
        </div >
    )
}

ValidationBar.propTypes = {
    annotationId: PropTypes.string,
    currentAnn: PropTypes.number,
    maxWidth: PropTypes.number,
    size: PropTypes.string,
    icons: PropTypes.bool,
    handler: PropTypes.func,
    isEdit: PropTypes.bool
}

ValidationBar.defaultProps = {
    annotationId: undefined,
    currentAnn: 0,
    maxWidth: null,
    size: "middle",
    icons: false,
    handler: undefined,
    isEdit: false
}
