import React, { useState, useContext, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";
import PropTypes from 'prop-types'
import { Layout, Menu, Dropdown, Button } from 'antd';
import {
    DownOutlined, ExportOutlined, PaperClipOutlined,
    LineChartOutlined, ScheduleOutlined, TableOutlined,
    PicLeftOutlined, UserOutlined, LogoutOutlined, FileSearchOutlined
} from '@ant-design/icons';

import SadaBreadcrumb from '../components/Breadcrumb.component';
import ExportScreen from '../components/Common/Export.component';
import { ProjectContext } from '../util/ProjectContext'
import { getUsername } from '../services/auth.service'

import logo from '../assets/imgs/logo_small_2.svg'

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

export default function SadaLayout(props) {
    const { cProject, reloadCProject } = useContext(ProjectContext);
    const [exportVisible, setExportVisible] = useState(false);
    const location = useLocation();
    const paths = location.pathname.split('/');
    const projectId = paths[2];

    useEffect(() => {
        if (projectId && projectId !== cProject?.id)
            reloadCProject(projectId);
    }, [projectId, cProject.id, reloadCProject]);

    const projectMenu =
        <Menu className="main-menu-project" onClick={(item) => { props.onMenuSelected && props.onMenuSelected(item.key); }}>
            <Menu.ItemGroup>
                <Menu.Item key="proj_page" icon={<PaperClipOutlined />}><Link to={`/projects/${projectId}/view`}>Project Page</Link></Menu.Item>
                <Menu.Item key="proj_browse" icon={<FileSearchOutlined />}><Link to={`/projects/${projectId}/browse`}>Browse Project</Link></Menu.Item>
                <Menu.Item key="proj_export" icon={<ExportOutlined />} onClick={() => setExportVisible(true)}>Export</Menu.Item>
            </Menu.ItemGroup>
            <Menu.Divider />
            <Menu.ItemGroup>
                <SubMenu key="ann_menu" icon={<ScheduleOutlined />} title="Annotation Results&nbsp;&nbsp;&nbsp;" className="main-menu-project">
                    <Menu.Item key="ann_annotate"><Link to={`/projects/${projectId}/annotate`} >Annotation Results</Link></Menu.Item>
                    <Menu.Item key="ann_relation"><Link to={`/projects/${projectId}/relations`} >Relations Results</Link></Menu.Item>
                    <Menu.Item key="ann_segment"><Link to={`/projects/${projectId}/segments`} >Segments Results</Link></Menu.Item>
                </SubMenu>
            </Menu.ItemGroup>
            <Menu.ItemGroup>
                <SubMenu key="stats_menu" icon={<LineChartOutlined />} title="Statistics" className="main-menu-project">
                    <Menu.Item key="stat_annotate"><Link to={`/projects/${projectId}/statistics/annotation`}>Annotation Statistics</Link></Menu.Item>
                    <Menu.Item key="stat_relation"><Link to={`/projects/${projectId}/statistics/relation`}>Relations Statistics</Link></Menu.Item>
                    <Menu.Item key="stat_segment"><Link to={`/projects/${projectId}/statistics/segment`}>Segments Statistics</Link></Menu.Item>
                </SubMenu>
            </Menu.ItemGroup>
            {location.pathname.indexOf('annotate') > -1 && <Menu.ItemGroup>
                <Menu.Divider />
                <SubMenu key="view_menu" title="View" className="main-menu-project">
                    <Menu.Item key="inline_view" icon={<PicLeftOutlined />}>Inline View</Menu.Item>
                    <Menu.Item key="grid_view" icon={<TableOutlined />} >Grid View</Menu.Item>
                </SubMenu>
            </Menu.ItemGroup>}
        </Menu>

    const userMenu =
        <Menu onClick={(item) => { props.onMenuSelected && props.onMenuSelected(item.key); }}>
            <Menu.Item icon={<LogoutOutlined />} key="logout" style={{ padding: '10px 15px' }}>Logout</Menu.Item>
        </Menu>

    return (
        <Layout id="sada-main-layout">
            <Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <img className="logo" src={logo} alt="logo" />
                <h1>Sada Annotation Portal</h1>
                <div>
                    {(location.pathname.indexOf(cProject?.id) !== -1) &&
                        <>
                            <Dropdown overlay={projectMenu} style={{ marginRight: "20px" }}>
                                <span className="ant-dropdown-link" style={{ cursor: "pointer", marginRight: "20px" }}>
                                    Project <DownOutlined />
                                </span>
                            </Dropdown>
                        </>
                    }
                    <Dropdown overlay={userMenu}>
                        <span className="ant-dropdown-link" style={{ cursor: "pointer" }}>
                            {getUsername()}
                            <Button type="primary" shape="circle" size="small" icon={<UserOutlined />} style={{ margin: "0 5px" }} />
                        </span>
                    </Dropdown>
                </div>
            </Header>
            <Content style={{ padding: '0 50px' }}>
                <SadaBreadcrumb />
                <div className="sada-layout-content">
                    {props.children}
                </div>
            </Content>
            <Footer style={{ textAlign: 'center', paddingBottom: '50px' }}>Sada Annotation ©{new Date().getFullYear()} Created by SadaSol</Footer>
            {cProject.id && <ExportScreen
                visible={exportVisible}
                projectId={cProject.id}
                onToggle={setExportVisible}
            />}
        </Layout>
    )
}

SadaLayout.propTypes = {
    onMenuSelected: PropTypes.func
}