import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Checkbox } from 'antd';

const { Search } = Input;

const SearchBox = (props) => {
  const [scope, setScope] = useState(props.selectedScope || ['annotation', 'relation']);
  const [q, setQ] = useState(props.query);

  const handleScopeChange = (s, checked) => {
    if (checked) setScope([...scope, s]);
    else setScope([...scope.filter(it => it !== s)]);
  }

  return (
    <div style={{ width: '60%', margin: '0 auto', marginBottom: 40 }}>
      <Search
        id="search_input"
        style={{ width: '100%', marginBottom: 10 }}
        placeholder="Search for items"
        onSearch={(v) => props.onSubmit(v, scope)}
        enterButton
        loading={props.loading}
        value={q}
        onChange={v => setQ(v.currentTarget.value)}
        allowClear
      />
      <div>
        <b>Search Scope:</b> &nbsp;&nbsp;
        <Checkbox checked={scope.includes('annotation')} onChange={(e) => handleScopeChange('annotation', e.target.checked)}>Annotations</Checkbox>
        <Checkbox checked={scope.includes('relation')} onChange={(e) => handleScopeChange('relation', e.target.checked)}>Relations</Checkbox>
      </div>
    </div>
  );
};

SearchBox.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  selectedScope: PropTypes.arrayOf(PropTypes.string).isRequired
}

SearchBox.defaultProps = {
}

export default SearchBox;