import React from 'react';
import PropTypes from 'prop-types';

const ColorsLegend = (props) =>
    <div className="color-key-container">
        {
            !props.browseMode &&
            <b>Colors Legend: &nbsp;&nbsp;</b>
        }
        {props.colors.map(c =>
            <div                
                key={c.color.background}
                className="color-key"
                style={{ backgroundColor: `${c.color.background}`, color: `${c.color.font}` }}>
                {c.name}
            </div>
        )}
    </div>

ColorsLegend.propTypes = {
    colors: PropTypes.arrayOf(Object).isRequired
}

export default ColorsLegend;