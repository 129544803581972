import React, { useState, useRef, useMemo, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Button, Row, Col, Divider, Pagination, Empty } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { useLazyEffect } from '../../util/lazy-effect.hook';
import Sticky from 'react-stickynode';

import ValidationBar from '../Common/ValidationBar.component';
import SegmentsList from './SegmentsList.component';

// eslint-disable-next-line
let prevAnn = -1;
let cachedAnnIndex = undefined;

const SegmentValidation = (props) => {

    const [currentAnn, _setCurrentAnn] = useState(-1);
    const currentAnnRef = useRef(currentAnn);

    const setCurrentAnn = data => {  // We are using this workaround to solve the issue that the listener can't access the current state value
        currentAnnRef.current = data;
        cachedAnnIndex = undefined;
        _setCurrentAnn(data);
    };

    useLazyEffect(() => {
        if (props.annList?.length) {
            addEventListeners();
            setCurrentAnn(cachedAnnIndex || 0);
        }
        return () => removeEventListeners();
    }, [props.annList[0]?.id, props.annList?.length]);

    const html = useMemo(() => {
        let _html = "";
        if (props.annList?.length) {
            props.annList.map(a => _html += a?.segment || '')
        }
        return _html;
    }, [props.annList]);

    useLazyEffect(() => {
        const nAnn = props.annList[currentAnn];
        if (!nAnn) return;

        if (prevAnn >= 0 && props.annList[prevAnn]) {
            const pEl = document.getElementById(props.annList[prevAnn].id);
            const pElC = document.getElementById("seg-" + props.annList[prevAnn].id);
            if (pElC) {
                ReactDOM.unmountComponentAtNode(pElC);
                pEl.classList.remove("selected-segment");
            }
        }

        const el = document.getElementById(nAnn.id);
        if (el) {
            el.classList.add("selected-segment");

            ReactDOM.render(
                <var
                    id="segment-validator"
                    onKeyDown={handleKeyDown}
                    style={{ lineHeight: 'normal', marginTop: 13, marginLeft: 0, zIndex: 900, position: 'absolute', right: 0 }}>
                    <div className="item"><b>Annotation: </b>{nAnn.type}</div>
                    <div className="item"><b>Current: </b>{nAnn.currentIteration.validation}</div>
                    {nAnn.prevIteration.validation && <div className="item"><b>Previous: </b>{nAnn.prevIteration.validation}</div>}
                    <div className="item"><b>Language: </b>{nAnn.currentIteration.language}</div>
                    <div className="item">
                        <span><b>Start: </b>{nAnn.start}</span> &nbsp;&nbsp;
                        <span><b>End: </b>{nAnn.end}</span>
                    </div>
                    <hr />
                    <ValidationBar
                        icons
                        annotationId={nAnn?.id || undefined}
                        handler={handleValidation}
                        currentAnn={currentAnn}
                        isEdit={nAnn?.validation?.value !== "TO-VALIDATE"} />
                </var>, document.getElementById("seg-" + nAnn.id), () => {
                    el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
                    const docCont = document.getElementById("segment-document-container");
                    const segVEl = document.getElementById("segment-validator");
                    if (segVEl?.getBoundingClientRect().right > docCont?.getBoundingClientRect().width) {
                        segVEl.style.marginLeft = `-${segVEl.getBoundingClientRect().width}px`;
                    }
                });
        }
    }, [currentAnn]);

    const isIndexInRange =
        useCallback((index) => (index + 1 >= props.annListMeta.start) && (index + 1 < (props.annListMeta.start + props.annListMeta.pageSize)),
            [props.annListMeta]);

    const findItemIndex = (id, index) => {
        if (index !== undefined && isIndexInRange(index)) {
            return index % props.annListMeta.pageSize;
        } else if (index !== undefined) {
            const pagination = {
                start: Math.floor(index / props.annListMeta.pageSize) * props.annListMeta.pageSize + 1  // The start is 1-based
            };
            cachedAnnIndex = index % props.annListMeta.pageSize;
            handleChange(pagination);
        } else {
            for (let i = 0; i < props.annList.length; i++)
                if (props.annList[i].id === id) return i;
        }
        return -1;
    }

    const handleSegmentSelect = (id, index) => {
        const ind = findItemIndex(id, index);
        if (ind >= 0) {
            prevAnn = currentAnnRef.current;
            setCurrentAnn(ind);
        }
    }

    const addEventListeners = () => {
        var elements = document.getElementsByClassName("segment-element");
        Array.from(elements).forEach(element => element.addEventListener('click', (e) => handleSegmentSelect(e.currentTarget.id)));
    }

    const removeEventListeners = () => {
        var elements = document.getElementsByClassName("segment-element");
        Array.from(elements).forEach(element => element.removeEventListener('click', (e) => handleSegmentSelect(e.currentTarget.id)));
    }

    const handleValidation = async (ids, v, index, isMulti) => {
        const res = await props.validationHandler(ids, v, index, isMulti);
        if (res) {
            const segElement = document.getElementById(ids); // TODO: handle this to work with multi Ids if we supported checkboxes (multi validation)
            if (segElement) {
                segElement.className = `segment-element ${"validation-" + v}`;
            }
            nextAnnotation();
        }
    }

    const handleKeyDown = (e) => {
        if (e.altKey || e.ctrlKey || e.shiftKey)
            return;

        const ann = props.annList[currentAnn];
        if (!ann?.id) return;
        switch (e.keyCode) {
            case 39: //next
            case 40: //down
                e.stopPropagation();
                e.preventDefault();
                nextAnnotation();
                break;
            case 37: //prev
            case 38: //up
                e.stopPropagation();
                e.preventDefault();
                prevAnnotation();
                break;
            case 90: //z
                if (ann.validation?.value === "TO-VALIDATE")
                    handleValidation(ann.id, "CORRECT-TYPE", currentAnn);
                break;
            case 88: //x
                if (ann.validation?.value === "TO-VALIDATE")
                    handleValidation(ann.id, "INCORRECT", currentAnn);
                break;
            case 67: //c
                if (ann.validation?.value === "TO-VALIDATE")
                    handleValidation(ann.id, "CORRECT", currentAnn);
                break;
            case 86: //v
                if (ann.validation?.value !== "TO-VALIDATE")
                    handleValidation(ann.id, "TO-VALIDATE", currentAnn);
                break;
            default:
                break;
        }
    }

    const nextAnnotation = () => {
        if ((currentAnn + 1) >= props.annList.length) return;
        prevAnn = currentAnn;
        setCurrentAnn(currentAnn + 1);
    }

    const prevAnnotation = () => {
        if ((currentAnn <= 0)) return;
        prevAnn = currentAnn;
        setCurrentAnn(currentAnn - 1);
    }

    const handleChange = (pagination) => {
        cachedAnnIndex === undefined && setCurrentAnn(-1);
        props.getAnnotations(pagination);
    }

    const annotation = props.annList[currentAnn] || {};

    return (
        <div>
            {!!props.annListMeta.total &&
                <div
                    tabIndex="0"
                    style={{ outline: 0 }}
                    onKeyDown={handleKeyDown} >
                    <Sticky Sticky className="StickyValidationBar-Outer segment" enabled={true} top={100} innerZ={999} activeClass="StickyAnnotateHeader-Active" innerClass="StickyValidationBar" >
                        <Row style={{ paddingTop: "15px" }}>
                            <Col span={12}>
                                {!!annotation?.id &&
                                    <ValidationBar
                                        annotationId={annotation.id}
                                        handler={handleValidation}
                                        currentAnn={currentAnn}
                                        isEdit={annotation?.validation?.value !== "TO-VALIDATE"} />}
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Button style={{ width: "120px" }} title="Previous annotation (left Key)" onClick={prevAnnotation} disabled={(currentAnn - 1) < 0}><LeftOutlined /> Previous</Button> &nbsp;
                                <Button style={{ width: "120px" }} title="Next annotation (Right Key)" onClick={nextAnnotation} disabled={(currentAnn + 1) >= props.annList.length}>Next <RightOutlined /></Button>
                            </Col>
                        </Row>
                    </Sticky>
                    <Divider />
                    <Row gutter={[0, 16]}>
                        <Col span={24}>
                            <Pagination
                                showSizeChanger
                                pageSize={props.annListMeta.pageSize}
                                onChange={(page, pageSize) => handleChange({ start: (page - 1) * pageSize + 1, pageSize })}
                                current={Math.floor((props.annListMeta.start - 1) / props.annListMeta.pageSize) + 1}
                                total={props.annListMeta.total}
                                size="small"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17}>
                            <div id="segment-document-container" dangerouslySetInnerHTML={{ __html: html }} />
                        </Col>
                        <Col span={7} style={{ padding: '0px 5px' }}>
                            <SegmentsList
                                currentAnn={currentAnn}
                                isIndexInRange={isIndexInRange}
                                handleSegmentSelect={handleSegmentSelect}
                                annListMeta={props.annListMeta}
                                lists={props.lists}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[0, 16]}>
                        <Col span={24}>
                            <Pagination
                                showSizeChanger
                                pageSize={props.annListMeta.pageSize}
                                onChange={(page, pageSize) => handleChange({ start: (page - 1) * pageSize + 1, pageSize })}
                                current={Math.floor((props.annListMeta.start - 1) / props.annListMeta.pageSize) + 1}
                                total={props.annListMeta.total}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>
            }
            {!props.annListMeta.total && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Segments to Validate!" />}
        </div >
    )
};

SegmentValidation.propTypes = {
    annList: PropTypes.arrayOf(Object),
    annListMeta: PropTypes.instanceOf(Object),
    lists: PropTypes.instanceOf(Object),
    validationHandler: PropTypes.func,
    getAnnotations: PropTypes.func,
}

SegmentValidation.defaultProps = {
    annList: [],
    annListMeta: {
        pageSize: 50,
        start: 1,
        total: 0
    },
    lists: {
        toc: [],
        solved: [],
        missed: []
    },
    validationHandler: undefined,
    getAnnotations: undefined
}

export default SegmentValidation;