import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

const ColumnSelectorComponent = (props) => {    
    return (
        <ul style={{ listStyle: "none", padding: 0 }}>
            {
                props.columns.map(col =>
                    <li key={col.key}>
                        <Checkbox
                            onChange={props.onChange}
                            value={col.key}
                            checked={props.visibleColsKeys?.includes(col.key) || false}>
                            {col.title}
                        </Checkbox>
                    </li>)
            }
        </ul>
    )
};

ColumnSelectorComponent.propTypes = {
    columns: PropTypes.arrayOf(Object).isRequired,
    visibleColsKeys: PropTypes.arrayOf(Object).isRequired,
    onChange: PropTypes.func.isRequired,
}

export default ColumnSelectorComponent;