
export const VALIDATION_FILTERS = [
    {
        text: 'CORRECT',
        value: 'CORRECT',
    },
    {
        text: 'INCORRECT',
        value: 'INCORRECT',
    },
    {
        text: 'MISSED',
        value: 'MISSED',
    },
    {
        text: 'SOLVED',
        value: 'SOLVED',
    },
    {
        text: 'CORRECT-TYPE',
        value: 'CORRECT-TYPE',
    },
    {
        text: 'TO-VALIDATE',
        value: 'TO-VALIDATE',
    },
];

export const nerTags = {
    'PERSON': {
        label: "People, including fictional.",
        example: "Fred Flintstone"
    },
    'NORP': {
        label: "Nationalities or religious or political groups.",
        example: 'The Republican Party'
    },
    'FAC': {
        label: "Buildings, airports, highways, bridges, etc.",
        example: 'Logan International Airport, The Golden Gate'
    },
    'ORG': {
        label: "Companies, agencies, institutions, etc.",
        example: 'Microsoft, FBI, MIT'
    },
    'GPE': {
        label: "Countries, cities, states.",
        example: 'France, UAR, Chicago, Idaho'
    },
    'LOC': {
        label: "Non-GPE locations, mountain ranges, bodies of water.",
        example: 'Europe, Nile River, Midwest'
    },
    'PRODUCT': {
        label: "Objects, vehicles, foods, etc. (Not services.)",
        example: 'Formula 1'
    },
    'EVENT': {
        label: "Named hurricanes, battles, wars, sports events, etc.",
        example: 'Olympic Games'
    },
    'WORK_OF_ART': {
        label: "Titles of books, songs, etc.",
        example: 'The Mona Lisa'
    },
    'LAW': {
        label: "Named documents made into laws.",
        example: 'Roe v. Wade'
    },
    'LANGUAGE': {
        label: "Any named language.",
        example: 'English'
    },
    'DATE': {
        label: "Absolute or relative dates or periods.",
        example: '20 July 1969'
    },
    'TIME': {
        label: "Times smaller than a day.",
        example: 'Four hours'
    },
    'PERCENT': {
        label: "Percentage, including " % ".",
        example: 'Eighty percent'
    },
    'MONEY': {
        label: "Monetary values, including unit.",
        example: 'Twenty Cents'
    },
    'QUANTITY': {
        label: "Measurements, as of weight or distance.",
        example: "Several kilometers,  '55kg"
    },
    'ORDINAL': {
        label: '"first","second", etc.',
        example: '9th, Ninth'
    },
    'CARDINAL': {
        label: "Numerals that do not fall under another type.",
        example: '2, Two, Fifty-two'
    },
}

export const statusColors = {
    'CORRECT': '#3bba3b',
    'INCORRECT': '#fa6b6b',
    'MISSED': '#f6b030',
    'CORRECT-TYPE': '#b6e566',
    'TO-VALIDATE': '#ffbd5d'
}