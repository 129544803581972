import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd';

export default function StatusBar(props) {
    return <Row className="status-bar" gutter={[24, 8]} justify="space-between">
        {
            Object.keys(props.data).map((key, index) => <Col key={"stat" + index}><b>{key}:&nbsp;</b>{props.data[key]}</Col>)
        }
    </Row>
}

StatusBar.propTypes = {
    data: PropTypes.object.isRequired,
}
