import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Tag, PageHeader, Radio, Divider, Skeleton, message } from 'antd';
import { useHistory } from "react-router-dom";
import Sticky from 'react-stickynode';

import InlineValidation from '../components/InlineValidation/InlineValidation.component'
import GridValidation from '../components/GridValidation/GridValidation.component'
import StatusBar from '../components/Common/StatusBar.component'
import AnnotationItemsDropDown from '../components/Common/AnnotationItemsDropDown.component'
import {
    getAnnotationsByType, validateAnnotation,
    getAnnotationStatus, getAnnTypeProgress, getFileProgress
} from '../services/annotation.service';
import { ProjectContext } from '../util/ProjectContext';
import { useLazyEffect } from '../util/lazy-effect.hook';

const AnnotatePage = (props) => {
    const { cProject } = useContext(ProjectContext);
    const [view, setView] = useState('file');    //file, annotationType, props.view === 'grid' ? 'annotationType' : 
    const [annList, setAnnList] = useState({
        meta: {
            pageSize: 100,
            start: 1,
            total: 0
        },
        data: [],
        segments: []
    });
    const [annStatus, setAnnStatus] = useState({});
    const [currentFile, setCurrentFile] = useState({});
    const [currentAnnotation, setCurrentAnnotation] = useState({});
    const [currentItemProgress, setCurrentItemProgress] = useState(-1);
    const history = useHistory();

    useLazyEffect(() => {
        if (cProject?.id && (cProject.status !== "DONE" || !cProject.iterations.length))
            history.push(window.location.pathname.replace("annotate", "upload"));
        else if (cProject?.id && (currentFile.id || currentAnnotation.id)) {
            getAnnotationList();
            fetchStatus();
        }
    }, [cProject.id, currentFile.id, currentAnnotation.id, view]);

    const getAnnotationList = async (pagination = {}, filters = '', sorter = '') => {
        const id = (view === 'file' ? currentFile.id : currentAnnotation.id);

        if (!id || !cProject.id)
            return;

        message.loading({
            content: 'Loading...',
            className: 'loading-backdrop',
            duration: 0
        });

        const res = await getAnnotationsByType({
            type: view,
            mode: props.view,
            id,
            projectId: cProject.id,
            filters,
            sorter,
            start: pagination?.start || annList.meta.start,
            pageSize: pagination?.pageSize || annList.meta.pageSize
        });
        if (res?.start > res?.total && res?.total > 0 && props.view === 'grid') { //To handel the pagination with filter issue            
            getAnnotationList({ ...pagination, start: (res.start - res.pageSize) }, filters, sorter);
            return;
        }
        if (res?.annotations) {
            setAnnList({
                meta: {
                    pageSize: res.pageSize,
                    start: res.start,
                    total: res.total
                },
                data: res.annotations,
                segments: res.segments
            });
        }
        message.destroy();
    }

    const handelGridChange = async (pagination, filter, sorter) => {
        let fStr = '', sStr = '';
        let paginationObj = {};
        if (pagination) {
            paginationObj.start = ((pagination.current - 1) * pagination.pageSize) + 1;
            paginationObj.pageSize = pagination.pageSize;
        }
        if (filter?.validation_current) {
            fStr = filter.validation_current.join(',');
        }
        if (sorter?.columnKey && sorter?.order) {
            sStr = `${sorter.column?.sortLabel || ''}-${sorter?.order?.replace('end', '') || ''}`;
        }
        getAnnotationList(paginationObj, fStr, sStr);
    }

    const fetchStatus = async () => {
        if (cProject?.id) {
            const res = await getAnnotationStatus({ projectId: cProject.id });
            if (res) {
                ['CORRECT-TYPE', 'MISSED', 'SOLVED'].forEach(k => delete res[k]);
                setAnnStatus(res);
            }
        }
    }

    const fetchItemProgress = async () => {
        let res;
        if (view === 'file') {
            res = await getFileProgress({ projectId: cProject.id, typeId: currentFile.id });
        } else if (view === 'annotationType') {
            res = await getAnnTypeProgress({ projectId: cProject.id, typeId: currentAnnotation.id });
        }
        if (res?.id)
            setCurrentItemProgress(res.progress);
    }

    const handleValidation = async (ids, v, index, isMulti) => {
        message.loading({
            content: 'Applying Validation...',
            className: 'loading-backdrop',
            duration: 0
        });
        const res = await validateAnnotation({ annotationIds: (isMulti ? ids : [ids]), validation: v });
        if (isMulti && res?.length) {
            const tList = annList.data.map(el => (ids.indexOf(el.id) >= 0 ?
                {
                    ...el,
                    validation: { ...el.validation, value: res[0].validation },
                    currentIteration: { ...el.currentIteration, validation: res[0].validation }
                } : el));
            setAnnList({ ...annList, data: tList });
            fetchItemProgress();
            fetchStatus();
        } else if (res && res[0]?.id) {
            const tRow = annList.data[index];
            if (tRow.validation?.value) {
                tRow.validation.value = res[0].validation;
                tRow.currentIteration.validation = res[0].validation;
            }
            setAnnList({ ...annList, data: [...annList.data.slice(0, index), tRow, ...annList.data.slice(index + 1)] });
            fetchItemProgress();
            fetchStatus();
        }
        message.destroy();
        return true
    }

    const handleViewChange = (e) => {
        setView(e.target.value)
    }

    return (
        <div>
            {!cProject.id && <Skeleton active paragraph={{ rows: 7 }} />}
            {!!cProject.id && <div>
                <Sticky enabled={true} top={10} innerZ={955} activeClass="StickyAnnotateHeader-Active" innerClass="StickyAnnotateHeader" >
                    <PageHeader
                        title="Validation"
                        style={{ padding: 0 }}
                        extra={[
                            <span key="labl">View By:</span>,
                            <Radio.Group key="btns" value={view} onChange={handleViewChange} >
                                <Radio.Button value="file" key="v1">Document</Radio.Button>
                                {props.view === "grid" && <Radio.Button value="annotationType" key="v2">Annotation Type</Radio.Button>}
                            </Radio.Group>
                        ]}
                        tags={(cProject?.iterations?.length ? <Tag color="blue">Round {cProject.iterations[cProject.iterations.length - 1].num}</Tag> : <Tag color="orange">Not Annotated</Tag>)}
                    />
                    <Row>
                        <Col span={24}>
                            {view === "file" && <div>
                                <AnnotationItemsDropDown
                                    validationMode='validation'
                                    type='documents'
                                    projectId={cProject.id}
                                    onSelectedItemChange={(v) => { setCurrentFile(v); }}
                                    currentItemProgress={currentItemProgress} />
                            </div>}
                            {view === "annotationType" && <div>
                                <AnnotationItemsDropDown
                                    validationMode='validation'
                                    type='annotations'
                                    projectId={cProject.id}
                                    onSelectedItemChange={(v) => { setCurrentAnnotation(v); }}
                                    currentItemProgress={currentItemProgress} />
                            </div>}
                        </Col>
                    </Row>
                </Sticky>
                <Row>
                    <Col span={24}>
                        <Divider style={{ margin: "12px 0" }} />
                        {props.view === "inline" && currentFile?.id
                            && <InlineValidation
                                annList={annList.data}
                                annListMeta={annList.meta}
                                segmentsList={annList.segments}
                                view={view}
                                validationHandler={handleValidation}
                                handelGridChange={handelGridChange}
                            />
                        }
                        {props.view === "grid" && (currentFile?.id || currentAnnotation?.id)
                            && <GridValidation
                                annList={annList.data}
                                annListMeta={annList.meta}
                                currentItemId={(view === 'file' ? currentFile.id : currentAnnotation.id)}
                                view={view}
                                validationHandler={handleValidation}
                                handelGridChange={handelGridChange}
                            />}
                    </Col>
                </Row>
                <Row>
                    <StatusBar data={annStatus} />
                </Row>
            </div>}
        </div>
    )
}

AnnotatePage.propTypes = {
    view: PropTypes.string
}

AnnotatePage.defaultProps = {
    view: "grid"
}
export default AnnotatePage;