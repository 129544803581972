import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Card, Skeleton, Drawer, Button, message, Divider, Popover, List, Popconfirm } from 'antd'
import { blue } from '@ant-design/colors';
import { EditOutlined, RightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

import ProjectFilesList from '../components/ProjectFiles.component'
import CartridgesList from '../components/CartridgesList.component'
import NewProject from '../components/NewProject.component'
import ProgressModal from '../components/Common/ProgressModal.component'
import { ProjectContext } from '../util/ProjectContext'
import { updateProject, removeFile } from '../services/projects.service'
import { annotationProgress, startAnnotation } from '../services/annotation.service'

const ProjectPage = () => {

    const [dVisible, setDVisible] = useState(false);
    const [mVisible, setMVisible] = useState(false);
    const [progress, setProgress] = useState(0);
    const { cProject, setCProject, reloadCProject } = useContext(ProjectContext);
    const history = useHistory();

    useEffect(() => {
        if (cProject?.id && cProject.status === "IN-PROGRESS" && !mVisible)
            handelProgress();
        else if (cProject?.id && cProject.status !== "DONE")
            history.push(window.location.pathname.replace("view", "upload"));
        // eslint-disable-next-line
    }, [cProject.id]);

    const handelEditProject = async (data) => {
        message.loading("Updating Project Details...", 0);
        const res = await updateProject({ id: cProject.id, ...data });
        if (res) {
            message.destroy();
            message.success("Project updated Successfully!");
            reloadCProject();
        }
        setDVisible(false);
    }

    const handelStartAnnotation = async () => {
        message.loading("Starting Annotation...", 0);
        if (cProject?.id)
            await startAnnotation({ projectId: cProject.id })
        message.destroy();
        handelProgress();
    };

    const handelViewResults = async (type) => {
        await reloadCProject(); //Reload the current project    
        history.push(window.location.pathname.replace("view", type));
    }

    const handelProgress = () => {
        setMVisible(true);
        let requestOut = false;
        const intervalTimer = setInterval(async () => {
            if (!requestOut) {
                requestOut = true;
                const res = await annotationProgress({ projectId: cProject.id });
                requestOut = false;
                if (res) {
                    setProgress(res.progress);
                    if (res.progress >= 1.0) {
                        clearInterval(intervalTimer);
                        // Finished // hide modal
                        if (res.sessionsCount === 0 && res.errorsCount === 0)
                        message.success("Annotation done Successfully!");
                        else
                        message.warning("Annotation done with some errors!");
                        setMVisible(false);
                    }
                } else {
                    message.error("Annotation Failed. Something went wrong!");
                    clearInterval(intervalTimer);
                    setMVisible(false);
                }
            }
        }, 1000);
    };

    const handelFilesChange = async (data, action) => {
        switch (action) {
            case "remove":
                message.loading("Deleting file...", 0);
                const res = await removeFile({ projectId: cProject.id, ...data });
                message.destroy();
                if (res)
                    setCProject({
                        ...cProject,
                        files: cProject.files.filter((f) => f.id !== data.id),
                    });
                break;
            default:
                break;
        }
    };

    const resultsListContent = <List size="small" style={{ padding: 0 }}>
        <List.Item style={{ padding: 0 }}><Button type="link" onClick={() => handelViewResults('annotate')}>Annotation Results</Button></List.Item>
        <List.Item style={{ padding: 0 }}><Button type="link" onClick={() => handelViewResults('relations')}>Relationships Results</Button></List.Item>
        <List.Item style={{ padding: 0 }}><Button type="link" onClick={() => handelViewResults('segments')}>Segments Results</Button></List.Item>
    </List>;

    return (
        <div>
            <ProgressModal mVisible={mVisible} progress={progress} />
            <Drawer
                title="Edit Project"
                width={500}
                onClose={() => { setDVisible(false); }}
                open={dVisible}
                bodyStyle={{ padding: "24px" }}
            >
                <NewProject onSubmit={handelEditProject} isEdit data={cProject} />
            </Drawer>
            {!cProject?.id && <Skeleton active paragraph={{ rows: 7 }} />}
            {!!cProject && !!cProject.id &&
                <>
                    <Row>
                        <Col span={10}>
                            <h2>{cProject.name}
                                <Button
                                    type="link"
                                    size="small"
                                    icon={<EditOutlined />}
                                    onClick={() => { setDVisible(true); }}
                                />
                            </h2>
                        </Col>
                        <Col span={14} style={{ textAlign: "right", marginBottom: "20px" }}>
                            <Popconfirm
                                title="Start new Annotation Round?"
                                icon={<QuestionCircleOutlined />}
                                onConfirm={handelStartAnnotation}>
                                <Button
                                    type="primary"
                                    style={{ marginRight: "20px" }}
                                >
                                    Annotate Round {parseInt(cProject.iterations.length ? cProject.iterations[cProject.iterations.length - 1].num : '0') + 1} <RightOutlined />
                                </Button>
                            </Popconfirm>
                            <Popover content={resultsListContent} title="Select Results Type" overlayClassName="view-results-popover" trigger="click">
                                <Button type="ghost">
                                    View Results <RightOutlined />
                                </Button>
                            </Popover>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <b style={{ color: blue[6] }}>15/3/2020 4:15 PM</b>
                            <p>{cProject.description}</p>
                        </Col>
                        <Col span={12}>
                            <Card title="Files and Plans" size="small" style={{ minHeight: "500px" }} bordered={false}>
                                <div>
                                    <ProjectFilesList
                                        filesList={cProject.files}
                                        onFilesChange={handelFilesChange}
                                        showDeleteConfirm />
                                    <Divider />
                                    <CartridgesList cartridges={cProject.cartridges} isView />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </>
            }
        </div >
    )
};

export default ProjectPage;