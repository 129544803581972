import axios from 'axios';
import { handelResponse } from '../util/HandelResponse'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

export const startAnnotation = async (data) => {
    try {
        const res = await axios.get(`/api/projects/${data.projectId}/process/start`);
        return handelResponse(res);
    } catch (error) {
        return handelResponse(error);
    }
};

export const annotationProgress = async (data) => {
    try {
        const res = await axios.get(`/api/projects/${data.projectId}/process/progress`);
        return handelResponse(res);
    } catch (error) {
        return handelResponse(error);
    }
};

export const getFilesList = async (data) => {
    try {
        const res = await axios.get(`/api/${data.mode}/${data.projectId}/get-filenames-list?page=${data.page || '1'}&pageSize=${data.pageSize || '10'}`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};

export const getAnnotationsList = async (data) => {
    try {
        const apiName = data.mode === 'relationships' ? 'get-templates-list' : 'get-annotation-types-list';
        const res = await axios.get(`/api/${data.mode}/${data.projectId}/${apiName}?page=${data.page || '1'}&pageSize=${data.pageSize || '10'}`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};

export const getAnnotationsByType = async (data) => {
    try {
        const res = await axios.get(`/api/validation/${data.projectId}/get-annotations/${data.type}/${encodeURIComponent(data.id)}${data.mode === 'inline' ? '/inline' : ''}?start=${data.start || '1'}&pageSize=${data.pageSize || '10'}&filter=${data.filters || ''}&sort=${data.sorter || ''}`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};

export const validateAnnotation = async (data) => {
    try {
        const res = await axios.put(`/api/validation/set-annotations-validation`, { annotationIds: data.annotationIds, validation: data.validation });
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};

export const getAnnotationStatus = async (data) => {
    try {
        const res = await axios.get(`/api/validation/${data.projectId}/get-annotations/status`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};

export const getAnnTypeProgress = async (data) => {
    try {
        const res = await axios.get(`/api/validation/${data.projectId}/get-annotation-type/${encodeURIComponent(data.typeId)}/progress`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};

export const getFileProgress = async (data) => {
    try {
        const res = await axios.get(`/api/validation/${data.projectId}/get-file/${data.typeId}/progress`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};