import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Row, Col } from 'antd';
import ResultMeta from './ResultMeta.component';

const ResultsList = (props) => {
  return (
    <div className="browse-results-list" style={{ marginBottom: 20 }}>
      {
        props.results.map(result => <Row key={result.id} style={{ marginBottom: 20, paddingBottom: 20, borderBottom: '1px solid #e3e3e3' }}>
          <Col span={15}>
            {!!result.type && <Tag color="orange">{result.type}</Tag>}
            <div
              className="browse-result-html"
              dangerouslySetInnerHTML={{ __html: result.segment }} />
          </Col>
          <Col span={9} style={{ paddingLeft: 20 }}>
            <ResultMeta
              scope={props.scope}
              result={result}
            />
          </Col>
        </Row>
        )
      }
    </div>
  );
};

ResultsList.propTypes = {
  results: PropTypes.arrayOf(Object).isRequired,
  scope: PropTypes.arrayOf(PropTypes.string).isRequired
}

ResultsList.defaultProps = {
}

export default ResultsList;