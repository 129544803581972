import axios from 'axios';
import { decode } from 'jsonwebtoken'

import { handelResponse } from '../util/HandelResponse'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

export const getAuthToken = () => localStorage.sada_tkn || sessionStorage.sada_tkn || null;

export const getUsername = () => localStorage.username || sessionStorage.username || null;

export const getAuthHeader = () => ({ Authorization: `Bearer ${getAuthToken()}` });

export const setHeaders = () => {
    axios.defaults.headers.common.Authorization = getAuthHeader().Authorization || {};
};


export const login = async (data) => {
    try {
        let res = await axios.post(`/api/auth/login`, data);
        if (res && res.data && res.data.access_token) {
            if (data.remember) {
                localStorage.sada_tkn = res.data.access_token;
                localStorage.username = res.data.user.username;
            }
            else {
                sessionStorage.sada_tkn = res.data.access_token
                sessionStorage.username = res.data.user.username;
            }

            setHeaders();
            return res.data;
        }
    } catch (error) {
        handelResponse(error);
    }
    delete localStorage.sada_tkn;
    delete localStorage.username;
    delete sessionStorage.sada_tkn;
    delete sessionStorage.username;
    return null;

};

export const logout = async (reload) => {
    try {
        // const res = await axios.post(`/api/auth/logout`, data);
        delete localStorage.sada_tkn;
        delete localStorage.username;
        delete sessionStorage.sada_tkn;
        delete sessionStorage.username;
        setHeaders();
        if (reload)
            window.location.reload();
    } catch (error) {
        handelResponse(error);

    }
    return null;

};

export const isAuth = () => {
    if (getAuthToken()) {
        const decoded = decode(getAuthToken());
        if (decoded.exp > Math.floor(Date.now() / 1000))
            return true
    }
    return false;
}