import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Select, Spin } from 'antd';
import { exportProjectByFormat, cancelExport } from '../../services/export.service';
import fileDownload from '../../util/file-download';


const { Option } = Select;

const ExportScreen = (props) => {
    const [loading, setLoading] = useState(false);
    const [format, setFormat] = useState('xml');
    const [scope, setScope] = useState('annotation');

    const handleOk = async () => {
        setLoading(true);
        const res = await exportProjectByFormat({
            projectId: props.projectId,
            format,
            scope
        });
        if (res)
            fileDownload(res, `Project_${scope}_${props.projectId}.${format}`);
        setLoading(false);
    }

    function handleChange(value) {
        setFormat(value);
    }

    return (
        <Modal
            title="Export Project"
            open={props.visible}
            closable={false}
            maskClosable={false}
            onOk={handleOk}
            okButtonProps={{ disabled: loading }}
            onCancel={() => { props.onToggle(false); cancelExport(); }}
        >
            <Spin
                style={{ backgroundColor: "#ffffff7a", color: "#2d2d2d" }}
                tip="Please wait for the file to be downloaded, this might take some minutes!"
                spinning={loading}
            >
                <p style={{ margin: '10px 0' }}>Export Scope: </p>
                <Select value={scope} style={{ width: 270 }} onChange={(v) => setScope(v)}>
                    <Option value="annotation">Annotations</Option>
                    <Option value="relation">Relations</Option>
                    <Option value="segment">Segments</Option>
                </Select>
                <br />
                <br />
                <p style={{ margin: '10px 0' }}>Export Format: </p>
                <Select value={format} style={{ width: 270 }} onChange={handleChange}>
                    <Option value="csv">Comma-separated Values (.csv)</Option>
                    <Option value="xml">XML Markup (.xml)</Option>
                    <Option value="xlsx">Common Spreadsheet Format (.xlsx)</Option>
                </Select>
            </Spin>
        </Modal >
    );
};

ExportScreen.propTypes = {
    visible: PropTypes.bool,
    projectId: PropTypes.string.isRequired,
    onToggle: PropTypes.func.isRequired
}

ExportScreen.defaultProps = {
    visible: false
}

export default ExportScreen;