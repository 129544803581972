import axios from 'axios';
import { handelResponse } from '../util/HandelResponse'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;


export const getList = async () => {
    try {
        const res = await axios.get(`/api/projects/`);
        return handelResponse(res);
    } catch (error) {
        return handelResponse(error);
    }
};

export const getProject = async (id) => {
    try {
        const res = await axios.get(`/api/projects/${id}`);        
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }

    return null;
};

export const getCartridges = async () => {
    try {
        const res = await axios.get(`/api/cartridges`);        
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }

    return null;
};

export const create = async (data) => {
    try {
        const res = await axios.post(`/api/projects/`, data);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
    return null;
};

export const updateProject = async (data) => {
    try {
        const res = await axios.put(`/api/projects/${data.id}`, data);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
    return null;
};

export const remove = async (data) => {
    try {
        const res = await axios.delete(`/api/projects/${data.id}`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }

    return null;
};

export const removeFile = async (data) => {
    try {
        const res = await axios.delete(`/api/projects/${data.projectId}/files/${data.id}`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }

    return null;
};

