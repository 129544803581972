import axios from 'axios';
import { handelResponse } from '../util/HandelResponse'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

export const search = async (payload) => {
    try {
        const res = await axios.post(`/api/browse/search/`, payload);
        return handelResponse(res);
    } catch (error) {
        return handelResponse(error);
    }
};

export const typeAhead = async (data) => {
    try {
        const res = await axios.get(`/api/typeahead?projectId=${data.projectId}&scope=${data.scope}&query=${data.query || '*'}&size=10`);
        return handelResponse(res);
    } catch (error) {
        return handelResponse(error);
    }
};