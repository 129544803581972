import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';

const BrowsePagination = (props) => {
  return (
    <div style={{ width: '100%', marginBottom: 20 }}>
      <Pagination
        onChange={props.onChange}
        responsive={true}
        size="default"
        showSizeChanger
        current={props.page}
        showTotal={total => `Total ${total} items `}
        total={props.total}
        pageSize={props.pageSize}
        page={props.page}
      />
    </div>
  );
};

BrowsePagination.propTypes = {
  onChange: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired
}

BrowsePagination.defaultProps = {
  total: 0,
  pageSize: 10,
  page: 1
}

export default BrowsePagination;