import axios from 'axios';
import { handelResponse } from '../util/HandelResponse'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
let cancelTokenSource;

export const exportProjectByFormat = async (data) => {
    try {
        cancelTokenSource = axios.CancelToken.source();        
        const res = await axios({
            method: 'get',
            url: `/api/export/${data.projectId}/${data.scope}/${data.format}`,
            responseType: 'blob',
            cancelToken: cancelTokenSource.token
        });
        return handelResponse(res);
    } catch (error) {
        return handelResponse(error);
    }
};

export const cancelExport = async () => {
    cancelTokenSource && cancelTokenSource.cancel();
}