import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Skeleton, Empty, Select, message } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { ProjectContext } from '../util/ProjectContext';
import { getStatistics, chartParams, initChartOptions } from '../services/statistics.service'

const { Option } = Select;

const StatisticsPage = () => {

    const { cProject } = useContext(ProjectContext);
    const [currentTypes, setCurrentTypes] = useState([]);
    const [statData, setStatData] = useState({});
    const history = useHistory();
    let { scope } = useParams();

    useEffect(() => {
        setCurrentTypes([]);
        if (cProject?.id && (cProject.status !== "DONE"))
            history.push(window.location.pathname.replace("statistics", "upload"));
        else if (cProject?.id)
            loadStatistics(cProject.id);
        // eslint-disable-next-line
    }, [cProject.id, scope]);

    const loadStatistics = async (projectId) => {
        message.loading({
            content: 'Loading...',
            className: 'loading-backdrop',
            duration: 0
        });
        const res = await getStatistics({ projectId, scope });
        if (res) {
            setStatData(res);
            // Show overall stats by default
            setCurrentTypes(["Overall"]);
        }
        message.destroy();
    }

    const getStatData = (annType, currentType) => {
        if (statData && statData.iterations) {
            //Non-ref copy of initial object
            const chartOptions = JSON.parse(JSON.stringify(initChartOptions));
            chartOptions.title.text = annType;
            statData.iterations.forEach(it => {
                chartOptions.xAxis.categories.push(`Iteration ${it.iterationNum}`);
            });

            chartParams.forEach(p => {
                if (currentType !== "Overall" && p.id === "accuracy")
                    return;
                chartOptions.series.push({
                    id: p.v,
                    name: p.label,
                    color: p.color,
                    data: statData.iterations.map(it =>
                    ({
                        y: it.statistics[annType][p.id] * 100,
                        count: (!!p.count ? `(${it.statistics[annType][p.count]})` : '')
                    })
                    )
                });
            });
            return chartOptions;
        }
    }

    const handleAnnotationTypeChange = (v) => {
        setCurrentTypes(v);
    }

    return (
        <div>
            {!cProject?.id && <Skeleton active paragraph={{ rows: 7 }} />}
            {!!cProject && !!cProject.id &&
                <>
                    <Row>
                        <Col span={12}>
                            <h2>Statistics</h2>
                        </Col>
                        <Col span={12}>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%', textAlign: 'left' }}
                                placeholder="Select Annotation Type"
                                onChange={handleAnnotationTypeChange}
                                showArrow={true}
                                value={currentTypes}
                            >
                                {statData.types?.map(t => <Option key={t}>{t}</Option>)}
                            </Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {!currentTypes.length &&
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    style={{ marginTop: '90px' }}
                                    description="Please Select Annotation Type" />
                            }
                            {!!currentTypes.length &&
                                currentTypes.map(type =>
                                    <div key={type}>
                                        <HighchartsReact
                                            key={type}
                                            highcharts={Highcharts}
                                            options={getStatData(type, type)}
                                        />
                                        <hr style={{ margin: '20px 0' }} />
                                    </div>
                                )
                            }
                        </Col>
                    </Row>
                </>
            }
        </div >
    )
};

export default StatisticsPage;