import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Progress, Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";

ProgressModal.propTypes = {
    mVisible: PropTypes.bool.isRequired,
    progress: PropTypes.number.isRequired
}

export default function ProgressModal(props) {
    return <Modal
        closable={false}
        open={props.mVisible}
        title=""
        footer={null}
        width="80vw"
        onCancel={() => {
            this.setState({ mVisible: false });
        }}
        maskClosable={false}
    >
        <div style={{ textAlign: "center", padding: "23vh 10vw" }}>
            <div>
                <Spin
                    size="large"
                    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
                <br />
                <br />
            </div>
            <div>
                <Progress
                    strokeColor={{
                        "0%": "#108ee9",
                        "100%": "#87d068",
                    }}
                    percent={(props.progress * 100).toFixed(1)}
                />
                <br />
                <br />
                <h3>Annotation in Progress, Please wait</h3>
            </div>
        </div>
    </Modal>
}