import axios from 'axios';
import { handelResponse } from '../util/HandelResponse'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

export const getStatistics = async (data) => {
    try {
        const res = await axios.get(`/api/statistics/${data.projectId}/${data.size || '5'}/${data.scope}`);
        return handelResponse(res);
    } catch (error) {
        return handelResponse(error);
    }
};

export const initChartOptions = {
    chart: {
        type: 'column'
    },
    credits: {
        enabled: false
    },
    title: {
        text: 'Main Chart Title'
    },
    xAxis: {
        categories: [],
        crosshair: true
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Ratio %'
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f}% </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        },
        series: {
            dataLabels: {
                enabled: true,
                format: '{point.y:.1f}%<br>{point.count}'
            }
        }
    },
    series: []
};

export const chartParams = [
    { id: 'accuracy', label: 'Accuracy', color: "#7cb5ec" },
    { id: 'fMeasure', label: 'F-Measure', color: "#434348" },
    { id: 'precession', label: 'Precession', color: "#90ed7d" },
    { id: 'recall', label: 'Recall', color: "#f7a35c" },
    { id: 'missedRatio', label: 'Missed', color: "#8085e9", count: 'missedCount' },
    { id: 'solvedRatio', label: 'Solved', color: "#f15c80", count: 'solvedCount' },
    { id: 'toValidateRatio', label: 'To Validate', color: "#e4d354", count: 'toValidateCount' }
];