import axios from 'axios';
import { handelResponse } from '../util/HandelResponse'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

export const getAnnotationsByType = async (data) => {
    try {
        const filters = data.filters || 'INCORRECT,CORRECT,TO-VALIDATE';
        const res = await axios.get(`/api/segments/${data.projectId}/get-segments/${data.type}/${encodeURIComponent(data.id)}?start=${data.start || '1'}&pageSize=${data.pageSize || '10'}&filter=${filters}&sort=order-asc`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};

export const validateSegment = async (data) => {
    try {
        const res = await axios.put(`/api/segments/set-segments-validation`, { Ids: data.segmentIds, validation: data.validation });
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};

export const getAnnotationStatus = async (data) => {
    try {
        const res = await axios.get(`/api/segments/${data.projectId}/get-segment/status`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};

export const getTemplateProgress = async (data) => {
    try {
        const res = await axios.get(`/api/segments/${data.projectId}/get-template/${encodeURIComponent(data.typeId)}/progress`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};

export const getFileProgress = async (data) => {
    try {
        const res = await axios.get(`/api/segments/${data.projectId}/get-file/${data.typeId}/progress`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};

export const getLists = async (data) => { // To get the TOC and solved/missed lists
    try {
        const res = await axios.get(`/api/segments/${data.projectId}/get-lists/file/${data.fileId}`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};