import { message } from 'antd'
import { logout } from '../services/auth.service'
export const handelResponse = (v) => {
    const data = v.response || v;

    // console.log(data);

    switch (data.status) {
        case 200:
        case 201:
            if (data.config.responseType === "blob") {
                return data.data;
            }
            if (data.data?.success) {
                return data.data.result || true;
            } else {
                const errorMsg = data.data?.errorResponse?.message || data.data?.errorResponse || "Something wrong happened!";
                message.destroy();
                message.error(errorMsg, 3);
            }
            break;

        case 401:
            console.log("UnAuthorized logging out...");
            logout(true);
            break;

        case 404:
            console.log("Service not found!");
            message.error("Server error please contact server admin!", 3);
            break;

        case 500:
            console.log("Server error please contact server admin");
            break;

        case 504:
            console.log("Please check your internet connection, and try again later");
            message.error("Please check your internet connection, and try again later", 3);
            break;

        default:
            break;
    }

    return null;
}