import React, { useState, useEffect } from "react";
import { Progress, Dropdown, Button, message, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { getFilesList, getAnnotationsList } from '../../services/annotation.service';
import useIsMounted from '../../util/is-mounted.hook';

const LOADING = 1;
const LOADED = 2;
const PAGE_SIZE = 100;
let listSize = 10000;
let itemStatusMap = {};
let loading = false;
const isItemLoaded = index => !!itemStatusMap[index];

export default function AnnotationItemsDropDown(props) {
    const isMounted = useIsMounted();

    const [itemsList, setItemsList] = useState([]);
    const [page, setPage] = useState(1);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [currentItem, setCurrentItem] = useState({});

    useEffect(() => {
        if (props.projectId && isMounted.current)
            loadMoreItems();
        // eslint-disable-next-line
    }, [props.projectId]);

    useEffect(() => {
        if (!currentItem?.id && isMounted.current) setCurrentItem(itemsList[0]);
        // eslint-disable-next-line
    }, [itemsList]);

    useEffect(() => {
        isMounted.current && setDropdownVisible(false);
        props.onSelectedItemChange && currentItem?.id && props.onSelectedItemChange(currentItem);
        // eslint-disable-next-line
    }, [currentItem]);

    useEffect(() => {
        if (props.currentItemProgress !== -1 && isMounted.current) {
            setItemsList(itemsList.map(item => item.id === currentItem.id ? { ...currentItem, progress: props.currentItemProgress } : item));
        }
        // eslint-disable-next-line
    }, [props.currentItemProgress]);

    useEffect(() => {
        // Reset page if the mode has changed
        setPage(1);
    }, [props.validationMode]);

    const handelSelectItem = (index) => {
        setCurrentItem(itemsList[index]);
    }

    const Row = (rowProps) => {
        const { index, style } = rowProps;
        const item = itemsList[index] ? itemsList[index] : undefined;
        return (
            <div key={index + 1} className="ListItem" style={{ ...style, fontWeight: (currentItem?.id === item?.id ? 'bold' : 'normal') }}>
                {!!item && // eslint-disable-next-line
                    <a onClick={() => handelSelectItem(index)}>
                        <Progress percent={Math.floor(item.progress * 100)} steps={5} size="small" />
                        <span className="annItem-dropdown-item">&nbsp;&nbsp;{index + 1}- {item.name}</span>
                    </a>
                }
                {!item && "Loading..."}
            </div>
        );
    };

    const loadMoreItems = async (startIndex, stopIndex) => {
        if (loading) return;
        for (let index = startIndex; index <= stopIndex; index++) {
            itemStatusMap[index] = LOADING;
        }
        if (props.projectId && isMounted.current) {
            loading = true;
            let res, items;
            if (!itemsList.length)
                message.loading({
                    content: 'Loading...',
                    className: 'loading-backdrop',
                    duration: 0
                });
            if (props.type === 'documents') {
                res = await getFilesList({ projectId: props.projectId, page, pageSize: PAGE_SIZE, mode: props.validationMode });
                items = res?.files || [];
                // eslint-disable-next-line
            } else if ((props.type === 'annotations' || props.type === 'templates') && isMounted.current) {
                res = await getAnnotationsList({ projectId: props.projectId, page, pageSize: PAGE_SIZE, mode: props.validationMode });
                items = res?.types || res?.templates || [];
            }
            message.destroy();
            if ((res?.files?.length || res?.types?.length  || res?.templates?.length) && isMounted.current) {
                for (let index = startIndex; index <= stopIndex; index++) {
                    itemStatusMap[index] = LOADED;
                }
                listSize = res?.total || 0;
                setItemsList([...itemsList, ...items]);
                setPage(page + 1);
            } else if (page === 1 && isMounted.current) {
                message.warning(`No ${props.type} found!`);
                listSize = 0;
                setDropdownVisible(false);
            }
        }
        loading = false;
    };

    return (
        <Dropdown
            trigger="click"
            overlayClassName="annItem-dropdown"
            open={dropdownVisible}
            onOpenChange={(v) => {
                if (listSize)  //Don't open dropdown if list is empty!
                    setDropdownVisible(v);
                else
                    setDropdownVisible(false);
            }}
            overlay={
                <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={listSize}
                    loadMoreItems={loadMoreItems}
                >
                    {({ onItemsRendered, ref }) => (
                        <List
                            className="List"
                            height={550}
                            itemCount={listSize}
                            itemSize={30}
                            onItemsRendered={onItemsRendered}
                            ref={ref}
                            style={{ overflow: "scroll", minWidth: "370px" }}
                        >
                            {Row}
                        </List>
                    )}
                </InfiniteLoader>
            }>
            <Button type="link" className="annItem-dropdown-btn" style={{ "paddingLeft": 0 }}>
                <b>{props.type === 'documents' ? 'Document' : 'Annotation'}: </b><Tooltip title={currentItem?.name}><span>{currentItem?.name}</span></Tooltip> <DownOutlined />
            </Button>
        </Dropdown>
    );
}

AnnotationItemsDropDown.propTypes = {
    onSelectedItemChange: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    validationMode: PropTypes.string.isRequired,
    currentItemProgress: PropTypes.number
}

AnnotationItemsDropDown.defaultProps = {
    currentItemProgress: -1
}