import React from 'react';
import PropTypes from 'prop-types'
import { List, Tabs } from 'antd';

const { TabPane } = Tabs;

const SegmentsList = (props) => {

  const isSelected = (index) => props.currentAnn === index % props.annListMeta.pageSize && props.isIndexInRange(index);

  return (
    <Tabs
      defaultActiveKey="1"
      type="card"
      style={{ position: 'sticky', top: '50px' }}>
      <TabPane tab="Segments Map" key="1">
        <List
          size="small"
          bordered
          style={{ maxHeight: '80vh', overflow: 'auto' }}
          dataSource={props.lists.toc}
          renderItem={
            (item, index) =>
              <List.Item
                style={{
                  cursor: 'pointer',
                  backgroundColor: (isSelected(index) ? '#f3f3f3' : 'unset')
                }}
                onClick={() => props.handleSegmentSelect(item.id, index)}
              >
                <b>{index + 1}&#41;</b> {item.type} ({item.start}-{item.end})
              </List.Item>
          }
        />
      </TabPane>
      <TabPane tab="Solved" key="2">
        <List
          size="small"
          bordered
          style={{ maxHeight: '80vh', overflow: 'auto' }}
          dataSource={props.lists.solved}
          renderItem={(item, index) => <List.Item><b>{index + 1}&#41;</b> {item.prevIteration?.type} ({item.start}-{item.end})</List.Item>}
        />
      </TabPane>
      <TabPane tab="Missed" key="3">
        <List
          size="small"
          bordered
          style={{ maxHeight: '80vh', overflow: 'auto' }}
          dataSource={props.lists.missed}
          renderItem={(item, index) => <List.Item> <b>{index + 1}&#41;</b> {item.prevIteration.type} ({item.start}-{item.end})</List.Item>}
        />
      </TabPane>
    </Tabs>
  );
};

SegmentsList.propTypes = {
  currentAnn: PropTypes.number.isRequired,
  isIndexInRange: PropTypes.func.isRequired,
  handleSegmentSelect: PropTypes.func.isRequired,
  annListMeta: PropTypes.instanceOf(Object).isRequired,
  lists: PropTypes.instanceOf(Object)
}

SegmentsList.defaultProps = {
  lists: {
    toc: [],
    solved: [],
    missed: []
  },
}

export default SegmentsList;