import React from 'react'
import PropTypes from 'prop-types'

import { Form, Input, Button, message } from 'antd';

export default function NewProject(props) {
    const onFinish = (values) => {
        props.onSubmit(values)
    };

    const onFinishFailed = (errorInfo) => {
        message.error("Please enter valid data!", 3);
    };

    return (
        <Form
            wrapperCol={{ span: 24 }}
            name="newProject"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <Form.Item
                name="name"
                rules={[{ required: true, message: 'Please enter the project name!' }]}
                initialValue={props.data?.name || ""}>
                <Input placeholder="Name" />
            </Form.Item>
            <Form.Item name="description" initialValue={props.data?.description || ""} >
                <Input.TextArea placeholder="Description" rows={5} />
            </Form.Item>

            <Form.Item style={{ textAlign: "right" }}>
                <Button type="primary" htmlType="submit">{`${props.isClone ? 'Clone' : (props.isEdit ? 'Edit' : 'Create')} Project`} </Button>
            </Form.Item>
        </Form >
    )
}

NewProject.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isClone: PropTypes.bool,
    isEdit: PropTypes.bool,
    data: PropTypes.instanceOf(Object)
}

NewProject.defaultProps = {
    isClone: false,
    isEdit: false,
    data: {}
}