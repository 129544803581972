import axios from 'axios';
import { handelResponse } from '../util/HandelResponse'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

export const getAnnotationsByType = async (data) => {
    try {
        const res = await axios.get(`/api/relationships/${data.projectId}/get-relation/${data.type}/${encodeURIComponent(data.id)}?start=${data.start || '1'}&pageSize=${data.pageSize || '10'}&filter=${data.filters || ''}&sort=${data.sorter || ''}`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};

export const validateRelation = async (data) => {
    try {
        const res = await axios.put(`/api/relationships/set-relations-validation`, { relationIds: data.relationIds, validation: data.validation });
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};

export const getAnnotationStatus = async (data) => {
    try {
        const res = await axios.get(`/api/relationships/${data.projectId}/get-relations/status`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};

export const getTemplateProgress = async (data) => {
    try {
        const res = await axios.get(`/api/relationships/${data.projectId}/get-template/${encodeURIComponent(data.typeId)}/progress`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};

export const getFileProgress = async (data) => {
    try {
        const res = await axios.get(`/api/relationships/${data.projectId}/get-file/${data.typeId}/progress`);
        return handelResponse(res);
    } catch (error) {
        handelResponse(error);
    }
};