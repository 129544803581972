import React, { Component } from 'react'
import { withRouter } from "react-router";
import { Row, Col, Card, message } from 'antd'

import ExistingProjects from '../components/ExistingProjects.component'
import NewProject from '../components/NewProject.component'

import { getList, create, remove } from '../services/projects.service'


class ProjectsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projects: null,
        }
    }

    componentDidMount() {
        this.getProjects();
    }

    getProjects = async () => {
        const pList = await getList();
        if (pList)
            this.setState({ projects: pList.list });
    }

    handelAddProject = async (data) => {
        message.loading("Creating new project...", 0);
        const res = await create(data);
        if (res?.id) {
            message.destroy();
            message.success("Project added successfully", 3);
            this.props.history.push(`/projects/${res.id}/upload`);
        } else {
            message.destroy();
            message.error("Failed to add project!", 3);
        }
    }

    handelCloneProject = async (data) => {
        message.loading("Cloning new project...", 0);
        const res = await create(data);
        message.destroy();
        if (res) {
            message.success("Project cloned successfully", 3);
            this.getProjects();
        } else {
            message.error("Failed to clone project!", 3);
        }
    }

    handelRemoveProject = async (data) => {
        message.loading("Deleting project...", 0);
        const res = await remove(data);
        message.destroy();
        if (res) {
            message.warning("Project Deleted!", 3);
            this.getProjects();
        }
    }

    render() {
        const { projects } = this.state;
        return (
            <Row>
                <Col span={10}>
                    <Card title="New Project" size="small" bodyStyle={{ margin: "15px 0", paddingLeft: 0 }} bordered={false}>
                        <NewProject onSubmit={this.handelAddProject} />
                    </Card>
                </Col>
                <Col span={14}>
                    <Card title="Browse Projects" size="small" bodyStyle={{ margin: "15px 0", paddingRight: 0 }} bordered={false}>
                        <ExistingProjects projects={projects} onRemove={this.handelRemoveProject} onClone={this.handelCloneProject} />
                    </Card>
                </Col>
            </Row>

        )
    }
}

export default withRouter(ProjectsPage);