export const replaceAt = (value, index, length, replacement) => {
    return value.substr(0, index) + replacement + value.substr(index + length);
};

export const convertToTitleCase = (text) => {
    return text.replace("-", " ").replace(/\b[a-z]/g, (x) => x.toUpperCase());
};

export const generateQueryString = (obj) => {
    const allowedTypes = ['string', 'number', 'object', 'boolean'];
    const keys = Object.keys(obj).filter(key => obj.hasOwnProperty(key) && allowedTypes.includes(typeof obj[key]));
    let queryString = '';
    for (const key of keys) {
        const val = encodeURIComponent(typeof obj[key] === 'object' ? (Object.entries(obj[key]).length || key === 'scope' ? JSON.stringify(obj[key]) : '') : obj[key]);
        if (val)
            queryString += queryString.length ? `&${key}=${val}` : `${key}=${val}`
    }
    return queryString;
};

export const uniqueBy = (uniqueKey, objects) => {
    const ids = objects.map(object => object[uniqueKey]);
    return objects.filter((object, index) => !ids.includes(object[uniqueKey], index + 1));
}

export const filterFacetsByScope = (scope, fa, facetScope) => {
    const facets = {}
    Object.keys(fa).forEach(key => {
        if (key && (scope?.includes(facetScope[key]) || facetScope[key] === '*')) {
            facets[key] = fa[key];
        }
    });
    return facets;
}