import { useRef, useEffect } from 'react';

/**
 *
 * An extension of useEffect hook that does not execute on the first render cycle.
 *
 * @param effect Imperative function that can return a cleanup function.
 * @param Optional If present, effect will only activate if the values in the list change.
 */
export const useLazyEffect = (effect, deps) => {
  const firstRender = useRef(true);
  // eslint-disable-next-line
  useEffect(() => firstRender.current ? undefined : effect ? effect() : undefined, deps);
  // eslint-disable-next-line
  useEffect(() => { firstRender.current = false }, []);
};