import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Tooltip, Popover } from 'antd';
import { ReloadOutlined, UnorderedListOutlined } from '@ant-design/icons';
import Sticky from 'react-stickynode';

import ResizeTable from '../Common/ResizeTable.component';
import ValidationBar from '../Common/ValidationBar.component';
import { ProjectContext } from '../../util/ProjectContext';
import { getConfig, setConfig } from '../../util/ConfigManager';
import ColumnSelectorComponent from './ColumnSelector.comonent';
import ColorsLegend from './ColorsLegend.component';
import { useLazyEffect } from '../../util/lazy-effect.hook';
import { VALIDATION_FILTERS } from '../../data/constansts';


const DEFAULT_VISIBLE_COLS = ['relationId', 'before', 'annotation', 'after', 'type', 'validation_current', 'validation_current', 'validation_prv'];
let currentGridConfig = {};

const RelationGridValidationRelationships = (props) => {
    const [currentAnn, setCurrentAnn] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentFilters, setCurrentFilters] = useState({});
    const [currentSorter, setCurrentSorter] = useState({});
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [visibleColsKeys, setVisibleColsKeys] = useState(getConfig('visibleCols') || DEFAULT_VISIBLE_COLS);
    const { cProject } = useContext(ProjectContext);

    let columns = useMemo(() => {
        let iterations = [];
        iterations[0] = cProject.iterations[cProject.iterations.length - 1];
        iterations[1] = cProject.iterations[cProject.iterations.length - 2];
        const cols = [
            {
                title: 'Relation ID',
                dataIndex: 'relationId',
                key: 'relationId',
                sorter: true,
                sortLabel: 'relation-id',
                sortDirections: ['ascend', 'descend'],
                sortOrder: currentSorter.columnKey === 'relationId' && currentSorter.order,
                ellipsis: {
                    showTitle: false
                },
                render: (v) => <Tooltip title={v} >{v.substring(0, 20)}{v.length > 20 && '...'}</Tooltip>
            },
            {
                title: 'Before',
                dataIndex: 'before',
                key: 'before',
                ellipsis: true
            },
            {
                title: 'Annotation',
                dataIndex: ['currentIteration', 'pointers'],
                key: 'annotation',
                render: (v) => <ul style={{ listStyle: 'none', padding: 0, margin: '-2px -8px' }}>
                    {v.map((rel, index) => <li style={{ padding: '3px 8px', borderBottom: index < v.length - 1 && '1px solid #fafafa' }} key={`${index}`}>{rel.text}</li>)}
                </ul>
            },
            {
                title: 'After',
                dataIndex: 'after',
                key: 'after',
                ellipsis: true
            },
            {
                title: 'Type',
                dataIndex: ['currentIteration', 'pointers'],
                key: 'type',
                render: (v) => <ul style={{ listStyle: 'none', padding: 0, margin: '-2px -8px' }}>
                    {v.map((rel, index) => <li style={{ padding: '3px 8px', borderBottom: index < v.length - 1 && '1px solid #fafafa' }} key={`${index}`}>{rel.name}</li>)}
                </ul>
            },
            {
                title: 'Begin',
                dataIndex: 'begin',
                key: 'begin',
                sortLabel: 'begin',
                sorter: false,
                sortDirections: ['ascend', 'descend'],
                sortOrder: currentSorter.columnKey === 'begin' && currentSorter.order,
            },
            {
                title: 'End',
                dataIndex: 'end',
                key: 'end',
                sortLabel: 'end',
                sortDirections: ['ascend', 'descend'],
                sorter: false,
                sortOrder: currentSorter.columnKey === 'end' && currentSorter.order
            },
            {
                title: `Validation ${iterations[0]?.num || '1'}`,
                dataIndex: ['currentIteration', 'validation'],
                key: 'validation_current',
                filters: VALIDATION_FILTERS,
                filterMultiple: true,
                filteredValue: currentFilters?.validation_current || [],
                // width: 120,
            },
        ];

        if (iterations[1])
            cols.splice(7, 0, {
                title: `Validation ${iterations[1].num}`,
                dataIndex: ['prevIteration', 'validation'],
                key: 'validation_prv',
                width: 120
            });

        return cols;
        // eslint-disable-next-line
    }, [cProject.iterations, visibleColsKeys, currentFilters?.validation_current, currentSorter]);

    useEffect(() => {
        setCurrentFilters({});
        setCurrentSorter({});
        setCurrentAnn(0);
        setCurrentPage(1);
        setSelectedKeys([]);
        // eslint-disable-next-line
    }, [props.view, props.currentItemId]);

    useEffect(() => {
        if (props.annListMeta.start)
            setCurrentPage(((props.annListMeta.start - 1) / props.annListMeta.pageSize) + 1);
        // eslint-disable-next-line
    }, [props.annListMeta.start, props.annListMeta.pageSize]);

    useLazyEffect(() => {
        setConfig('visibleCols', visibleColsKeys);
    }, [visibleColsKeys]);

    const reloadTableData = (pagination, filters, sorter) => {
        //If this was called from the reload button
        if (pagination || filters || sorter) {
            currentGridConfig = { pagination, filters, sorter };
        }

        if (props.handelGridChange)
            props.handelGridChange(currentGridConfig.pagination, currentGridConfig.filters, currentGridConfig.sorter);

        if (pagination?.current) setCurrentPage(pagination.current);
        if (filters) setCurrentFilters(filters);
        if (sorter) setCurrentSorter(sorter);
    }

    const onSelectChange = (selectedRowKeys) => {
        setSelectedKeys(selectedRowKeys);
        if (selectedRowKeys.length > 1)
            setCurrentAnn(-1);
    };

    const handleValidation = async (id, validation, cIndex) => {
        if (id !== undefined)
            await props.validationHandler(id, validation, cIndex, false);
        else
            await props.validationHandler(selectedKeys, validation, -1, true);

        if (currentFilters)
            reloadTableData();
        setSelectedKeys([]);
        setCurrentAnn(-1);
    }

    const handleKeyDown = (e) => {
        if (e.altKey || e.ctrlKey || e.shiftKey)
            return;

        const ann = props.annList[currentAnn];
        if (!ann?.id) return;
        switch (e.keyCode) {
            case 39: //next
            case 40: //down
                e.stopPropagation();
                e.preventDefault();
                nextAnnotation();
                break;
            case 37: //prev
            case 38: //up
                e.stopPropagation();
                e.preventDefault();
                prevAnnotation();
                break;
            case 90: //z
                if (ann.validation?.value === "TO-VALIDATE")
                    handleValidation(ann.id, "CORRECT-TYPE", currentAnn);
                break;
            case 88: //x
                if (ann.validation?.value === "TO-VALIDATE")
                    handleValidation(ann.id, "INCORRECT", currentAnn);
                break;
            case 67: //c
                if (ann.validation?.value === "TO-VALIDATE")
                    handleValidation(ann.id, "CORRECT", currentAnn);
                break;
            case 86: //v
                if (ann.validation?.value !== "TO-VALIDATE")
                    handleValidation(ann.id, "TO-VALIDATE", currentAnn);
                break;
            default:
                break;
        }
    }

    const nextAnnotation = () => {
        const isLastPage = (currentPage >= props.annListMeta.total / props.annListMeta.pageSize);
        const isLastItem = isLastPage && (currentAnn + 1 >= (props.annListMeta.pageSize - ((Math.ceil(props.annListMeta.total / props.annListMeta.pageSize) * props.annListMeta.pageSize) - props.annListMeta.total)));
        if (isLastItem || (currentAnn + 1 >= (props.annListMeta.pageSize))) return;
        setCurrentAnn(currentAnn + 1);
    }

    const prevAnnotation = () => {
        const isFirstItem = (currentAnn <= 0);
        if (isFirstItem) return;
        setCurrentAnn(currentAnn - 1);
    }

    const handelSelectedColChange = (e) => {
        if (e.target.checked)
            setVisibleColsKeys([...visibleColsKeys.filter(el => el !== e.target.value), e.target.value]);
        else
            setVisibleColsKeys([...visibleColsKeys.filter(el => el !== e.target.value)]);
    }

    const relation = props.annList[currentAnn];

    const TableTitle = () => (
        <div >
            <Tooltip title="Show/ Hide Columns">
                <Popover trigger="click" content={
                    <ColumnSelectorComponent
                        columns={columns}
                        visibleColsKeys={visibleColsKeys}
                        onChange={handelSelectedColChange} />}>
                    <Button
                        icon={<UnorderedListOutlined />}
                        shape="circle"
                    >
                    </Button>
                </Popover>
            </Tooltip>
            <Tooltip title="Reload Data">
                <Button
                    onClick={() => reloadTableData()}
                    icon={<ReloadOutlined />}
                    shape="circle"
                >
                </Button>
            </Tooltip>
            <Sticky className="StickyValidationBar-Outer" style={{ display: 'inline-block', background: 'red' }} enabled={true} top={100} innerZ={1005} activeClass="StickyAnnotateHeader-Active" innerClass="StickyValidationBar" >
                <div className={(selectedKeys.length ? '' : 'disabled')}>
                    <ValidationBar annotationId={relation?.id || undefined} handler={handleValidation} currentAnn={currentAnn} />
                </div>
            </Sticky>
        </div>
    )

    return (
        <div
            tabIndex="0"
            style={{ outline: 0 }}
            onKeyDown={handleKeyDown} >
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                        {!!relation?.segment && <div dangerouslySetInnerHTML={{ __html: relation.segment }} />}
                    </div>
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <ColorsLegend colors={props.colors} />
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24}>
                    <ResizeTable
                        size="small"
                        bordered
                        className="grid-view-table"
                        rowKey="id"
                        showSorterTooltip={false}
                        style={{ cursor: 'pointer' }}
                        title={TableTitle}
                        rowSelection={{
                            columnWidth: 30,
                            hideSelectAll: false,
                            onChange: onSelectChange,
                            selectedRowKeys: selectedKeys,
                        }}
                        onRow={(record, index) => ({
                            onClick: () => {
                                setCurrentAnn(index);
                                if (selectedKeys.length && selectedKeys.indexOf(record.id) === -1)
                                    setSelectedKeys([...selectedKeys, record.id])
                            },
                        })}
                        scroll={{ x: 1000 }}
                        scrollToFirstRowOnChange={true}
                        dataSource={props.annList}
                        pagination={{
                            showQuickJumper: true,
                            position: ["topRight", "none"],
                            pageSize: props.annListMeta.pageSize,
                            total: props.annListMeta.total,
                            showSizeChanger: true,
                            current: currentPage,
                            className: "grid-validation-pagination"
                        }}
                        onChange={reloadTableData}
                        columns={[...columns.filter(col => visibleColsKeys?.includes(col.key)), {
                            title: 'Actions',
                            key: 'actions',
                            render: (v) => (props.annList[currentAnn]?.id === v.id ?
                                <ValidationBar
                                    icons
                                    size="medium"
                                    annotationId={v.id}
                                    handler={handleValidation}
                                    currentAnn={currentAnn}
                                    isEdit={props.annList[currentAnn]?.validation?.value !== "TO-VALIDATE"} />
                                : ""),
                            fixed: "right",
                            width: 180,
                        }]} />
                </Col>
            </Row>
        </div>
    )
};

RelationGridValidationRelationships.propTypes = {
    view: PropTypes.string.isRequired,
    annList: PropTypes.arrayOf(Object),
    colors: PropTypes.arrayOf(Object),
    annListMeta: PropTypes.instanceOf(Object),
    validationHandler: PropTypes.func,
    handelGridChange: PropTypes.func,
}

RelationGridValidationRelationships.defaultProps = {
    annList: [],
    colors: [],
    annListMeta: {
        pageSize: 10,
        start: 1,
        total: 0
    },
    validationHandler: undefined,
    handelGridChange: undefined
}

export default RelationGridValidationRelationships;