import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Checkbox, Button, Tooltip } from 'antd';
import { convertToTitleCase } from '../../util/utils';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useLazyEffect } from '../../util/lazy-effect.hook';

const { Panel } = Collapse;

const Facets = (props) => {
  const [selected, setSelected] = useState(props.selectedFacets || {});  
  useLazyEffect(() => {
    setSelected(props.selectedFacets);
  }, [props.selectedFacets])

  const noFacets = useMemo(() => {
    const keys = Object.keys(props.facets);
    for (let i = 0; i < keys.length; i++) if (props.facets[keys[i]].length) return false;
    return true;
  }, [props.facets]);

  const handleFacetChecked = (facet, values) => {
    if (values.length)
      setSelected({ ...selected, [facet]: values });
    else if (Object.keys(selected).length)
      setSelected({ ...selected, [facet]: undefined });
  }

  return (
    <div style={{ marginBottom: 20 }}>
      <b style={{ display: 'flex', marginBottom: 5, justifyContent: 'space-between' }}>Facets:
        {
          !!Object.keys(selected).length &&
          <Button
            type="link"
            size="small"
            onClick={() => props.onClearAll()}
          >Clear ALL</Button>
        }
      </b>
      <Collapse
        defaultActiveKey={['0_', '1_', '2_', '3_', '4_', '5_', '6_', '7_']}>
        {
          !noFacets && Object.keys(props.facets).map((k, index) => (props.facets[k].length &&
            <Panel
              key={index + '_'}
              header={convertToTitleCase(k)}
              extra={
                <span>
                  {!!selected[k]?.length && <Tooltip title="Clear All">
                    <Button
                      type="dashed" shape="circle" icon={<CloseOutlined />}
                      size="small"
                      danger
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelected({ ...selected, [k]: undefined });
                        if (props.selectedFacets[k]?.length)
                          props.onSubmit(k, []);
                      }} /></Tooltip>}
                  &nbsp;
                  <Tooltip title="Apply">
                    <Button
                      type="dashed" shape="circle" icon={<CheckOutlined />}
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onSubmit(k, selected[k]);
                      }} />
                  </Tooltip>
                </span>
              }
            >
              <Checkbox.Group
                key={k}
                className="facets-checkbox-group"
                options={props.facets[k].map(fa => ({ label: `${fa.lable} (${fa.count}) `, value: fa.lable }))}
                value={[...(selected[k] || [])]}
                onChange={(values) => handleFacetChecked(k, values)}
              />
            </Panel>
          ))
        }
      </Collapse>
      {
        !!noFacets && !props.loading && <div style={{ marginTop: 10 }}>No Facets to show!</div>
      }
    </div>
  );
};

Facets.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  facets: PropTypes.instanceOf(Object).isRequired,
  selectedFacets: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  onClearAll: PropTypes.func.isRequired
}

Facets.defaultProps = {
}

export default Facets;