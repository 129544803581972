import React from 'react';
import PropTypes from 'prop-types';
import { Card, Tag, Divider } from 'antd';
import { convertToTitleCase } from '../../util/utils'

const CurrentFilters = (props) => {
  const facetKeys = Object.keys(props.selectedFacets);

  return (
    <div style={{ marginBottom: 20 }}>
      <h3>Current Filters:</h3>
      <hr />
      <Card bodyStyle={{ padding: 6 }}>
        {
          facetKeys.map((key, ind) => !!props.selectedFacets[key]?.length && <div key={key}>
            <b>{convertToTitleCase(key)}:</b><br />
            {props.selectedFacets[key].map((v, vInd) => <span key={v + vInd}> <Tag
              style={{ margin: 2, whiteSpace: 'break-spaces' }}
              closable
              onClose={(e) => {
                props.onDelete(key, v);
                e.preventDefault();
              }}
            >{v}</Tag> {vInd !== props.selectedFacets[key].length - 1 && <span>&nbsp;OR&nbsp;</span>}</span>
            )}
            {
              ind !== facetKeys.length - 1 &&
              <Divider style={{ margin: 0, padding: '0px 15px' }} orientation="center" plain >AND</Divider>
            }
          </div>
          )
        }
        {
          !facetKeys.length && <Tag>Select Filters Below</Tag>
        }
      </Card>
    </div>
  );
};

CurrentFilters.propTypes = {
  onDelete: PropTypes.func.isRequired,
  selectedFacets: PropTypes.instanceOf(Object).isRequired
}

CurrentFilters.defaultProps = {
}

export default CurrentFilters;