import React, { useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './assets/styles/App.less';

import ProjectsPage from './containers/Projects.container';
import UploadPage from './containers/Upload.container';
import ProjectPage from './containers/Project.container';
import StatisticsPage from './containers/Statistics.container';
import SadaLayout from './containers/Layout.container'
import AnnotatePage from './containers/Annotate.container';
import ValidateRelationships from './containers/ValidateRelationships.container';
import ValidateSegments from './containers/ValidateSegments.container';
import LoginPage from './containers/Login.container';
import BrowsePage from './containers/Browse.container';
import ProtectedRoute from './components/Common/ProtectedRoute'

import { getConfig, setConfig } from './util/ConfigManager';
import { logout, setHeaders, isAuth } from './services/auth.service';
import { ProjectProvider } from './util/ProjectContext';
import { useLazyEffect } from './util/lazy-effect.hook';

const App = () => {
  const [selectedView, setSelectedView] = useState(getConfig("selectedView") || "grid");
  useLazyEffect(() => {
    setConfig("selectedView", selectedView);
  }, [selectedView]);

  if (!isAuth()) logout();
  setHeaders();


  const handelMenuItem = (key) => { //TODO: move this to inside the layout
    switch (key) {
      case 'inline_view': {
        setSelectedView("inline");
        break;
      }
      case 'grid_view': {
        setSelectedView("grid");
        break;
      }
      case 'edit': {
        setSelectedView("grid");
        break;
      }
      case 'logout': {
        logout(true);
        break;
      }
      default:
        break;
    }
  }

  return (
    <BrowserRouter>
      <ProjectProvider>
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          <SadaLayout onMenuSelected={handelMenuItem}>
            <Switch>
              <ProtectedRoute path="/projects/:id/view" exact component={ProjectPage} />
              <ProtectedRoute path="/projects/:id/annotate" exact component={props => <AnnotatePage {...props} view={selectedView} />} />
              <ProtectedRoute path="/projects/:id/upload" exact component={UploadPage} />
              <ProtectedRoute path="/projects/:id/statistics/:scope" exact component={StatisticsPage} />
              <ProtectedRoute path="/projects" exact component={ProjectsPage} />
              <ProtectedRoute path="/projects/:id/relations" exact component={ValidateRelationships} />
              <ProtectedRoute path="/projects/:id/segments" exact component={ValidateSegments} />
              <ProtectedRoute path="/projects/:id/browse" exact component={BrowsePage} />
              <Redirect to="/projects" />
            </Switch>
          </SadaLayout>
        </Switch>
      </ProjectProvider>
    </BrowserRouter>
  );
}

export default App;
