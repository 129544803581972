import React, { useState } from 'react';
import PropTypes from 'prop-types'

import { Upload, message, Button, Divider } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { getAuthHeader } from '../services/auth.service'

const { Dragger } = Upload;

const ProjectUpload = (props) => {
  const [filesList, setFilesList] = useState([]);
  const [queue, setQueue] = useState([]);

  const uploaderProps = {
    name: 'file',
    multiple: true,
    accept: '.txt,.pdf,.doc,.docx,.zip,.msg,.xml',
    action: `/api/projects/${props.projectId}/files/upload`,
    headers: { ...getAuthHeader() },
    onChange(info) {
      const q = info.fileList.filter((f) => (f.status !== "done"));
      setQueue(q);
      
      const { status } = info.file;      
      if (status === 'done' && info.file?.response?.success) {
        const file = info.file.response.result[0];
        filesList.push(file);
        setFilesList([...filesList, file]);        
        if (props.onFilesChange) {
          props.onFilesChange(file, 'add');
        }
      } else if (status === 'done' && !info.file?.response?.success) {
          message.error(`Failed to Upload file (${info.file.name})!`);
      }
      else if (status === 'error') {
          message.error(`Failed to Upload file (${info.file.name})!`);
      }
    },
  };


  return (
    <div className="uploader-card" >
      <div style={{ marginTop: "10px" }}>
        <Dragger {...uploaderProps} style={{ border: "unset", background: "unset" }} showUploadList={true} fileList={queue}>

          <div className="custom-uploader">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">Please select files of types doc, docx, pdf, txt, msg, xml, zip
          </p>
          </div>

          <Divider>Or</Divider>

          <Button style={{ width: "100%" }}>
            <UploadOutlined /> Click to Upload
          </Button>
        </Dragger>
      </div>

    </div>
  );
};

ProjectUpload.propTypes = {
  projectId: PropTypes.string.isRequired
}

export default ProjectUpload;