import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Input, Checkbox, Button, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logo from '../assets/imgs/logo_small_2.svg'

import { login, isAuth } from '../services/auth.service'

const LoginPage = () => {

    const history = useHistory();
    useEffect(() => {
        if (isAuth()) {
            //if user is already logged in, redirect to projects page
            history.push('/projects');
        }
    });

    const handelLogin = async (data) => {
        message.loading("Logging In, Please wait...", 0);
        const res = await login(data);
        message.destroy();
        if (res) {
            history.push('/projects')
        } else {
            message.error("Please check your username and password!", 3);
        }
    }

    return (
        <div>
            <Form
                wrapperCol={{ span: 24 }}
                name="loginForm"
                initialValues={{ remember: true }}
                style={{ margin: "0 auto ", marginTop: "15vh", width: "450px" }}
                onFinish={handelLogin}
            >
                <Form.Item>
                    <img src={logo} style={{ display: "block", margin: "50px auto", width: "400px" }} alt="logo" />
                    <h2 className="login-label" style={{ textAlign: "center", color: "#3F61AD" }}>Sada Annotation</h2>
                </Form.Item>
                <Form.Item                    
                    name="username"
                    rules={[{ required: true, message: 'Please enter your username!' }]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                <Form.Item                   
                    name="password"
                    rules={[{ required: true, message: 'Please enter your password!' }]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder="Password" />
                </Form.Item>
                <Form.Item>
                    <Input.Group compact>
                        <Form.Item name="remember" valuePropName="checked">
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        <Form.Item style={{ float: "right" }}>
                            <Button type="primary" htmlType="submit" style={{ width: "100px" }}>Login</Button>
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
            </Form>
        </div >
    )
}

export default LoginPage;