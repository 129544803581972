import React from 'react';
import PropTypes from 'prop-types'
import { List, Popconfirm } from 'antd';
import { DeleteOutlined, FileTextOutlined, FileZipOutlined } from '@ant-design/icons';

const ProjectFilesList = (props) => {

  const getListItemData = (file) => {
    let icon = <FileTextOutlined />;
    let name = file.name;
    if (file.type === 'application/zip') {
      icon = <FileZipOutlined />;
      name += ` (${file.count} files)`
    }
    return (<> {icon} {name}</>);
  };

  return (
    <List
      header={"Selected Files"}
      size="small"
      bordered
      rowKey="uid"
      locale={{ emptyText: "No Files Selected!" }}
      className="files-list"
      dataSource={props.filesList}
      renderItem={f =>
        <List.Item>  {getListItemData(f)} {
          !props.isView &&
          (
            props.showDeleteConfirm ?
              <Popconfirm
                title="Are You sure you want to delete this file?"
                onConfirm={() => props.onFilesChange && props.onFilesChange(f, 'remove')}>
                <DeleteOutlined className="file-delete-btn" />
              </Popconfirm> :
              <DeleteOutlined onClick={() => { props.onFilesChange && props.onFilesChange(f, 'remove') }} className="file-delete-btn" />
          )
        }
        </List.Item>
      }
    />
  );
};

ProjectFilesList.propTypes = {
  filesList: PropTypes.arrayOf(Object).isRequired,
  isView: PropTypes.bool,
  showDeleteConfirm: PropTypes.bool,
  onFilesChange: PropTypes.func
}

ProjectFilesList.defaultProps = {
  isView: false,
  showDeleteConfirm: false,
}


export default ProjectFilesList;