import React, { createContext, useState } from 'react';
import { getProject } from '../services/projects.service'

export const ProjectContext = createContext({});

export const ProjectProvider = (props) => {
    const [cProject, setCProject] = useState({});

    const reloadCProject = async (projectId) => {
        if (projectId || cProject.id) {
            const res = await getProject(projectId || cProject.id);
            setCProject(res || {});
        }
    }

    return (
        <ProjectContext.Provider value={{ cProject, setCProject, reloadCProject }}>
            {props.children}
        </ProjectContext.Provider>
    );
}
