import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Tooltip, Spin, Button } from 'antd';
import { typeAhead } from '../../services/browse.service';
import useDebounce from '../../util/debounce.hook';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { uniqueBy } from '../../util/utils';

const { Option } = Select;

const FilesFilter = (props) => {
  const [filesList, setFilesList] = useState([]);
  const [selected, setSelected] = useState(props.selectedFiles);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const searchTermDebounced = useDebounce(searchTerm, 800);

  useEffect(() => {
    searchTermDebounced && handleSearch();
    // eslint-disable-next-line
  }, [searchTermDebounced]);

  // eslint-disable-next-line
  useEffect(() => { handleSearch(); }, []);
  
  // eslint-disable-next-line
  useEffect(() => { setFilesList(uniqueBy('id', [...filesList, ...props.initialList])) }, [props.initialList]);

  const handleSearch = async () => {
    setLoading(true);
    const res = await typeAhead({ query: searchTerm, projectId: props.projectId, scope: 'file' });
    setFilesList(res || []);
    setLoading(false);
  }

  return (
    <div style={{ marginBottom: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
        <b>Files Scope:</b>
        {/* <Button type="link" size="small" onClick={() => props.onSubmit(selected)}>Apply</Button> */}
        <span>
          {!!selected?.length && <Tooltip title="Clear All">
            <Button
              type="dashed" shape="circle" icon={<CloseOutlined />}
              size="small"
              danger
              onClick={(e) => {
                e.stopPropagation();
                setSelected([]);
                if (props.selectedFiles.length)
                  props.onSubmit([]);
              }} /></Tooltip>}
          &nbsp;
          <Tooltip title="Apply">
            <Button
              type="dashed" shape="circle" icon={<CheckOutlined />}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                props.onSubmit(selected);
              }} />
          </Tooltip>
          &nbsp;
          &nbsp;
        </span>
      </div>
      <Select
        showSearch
        style={{ width: '100%' }}
        placeholder="Search for a file"
        mode="multiple"
        optionFilterProp="children"
        onSearch={(q) => setSearchTerm(q)}
        value={selected}
        filterOption={false}
        notFoundContent={loading ? <div style={{ textAlign: 'center' }}><Spin size="small" /></div> : <span>Not found!</span>}
        onChange={files => setSelected(files)}
      >
        {
          filesList.map(f => <Option key={f.id} value={f.id}>
            <Tooltip title={f.name}>{f.name}</Tooltip>
          </Option>)
        }
      </Select>
    </div>
  );
};

FilesFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  selectedFiles: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialList: PropTypes.arrayOf(Object).isRequired
}

export default FilesFilter;