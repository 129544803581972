import React, { useContext } from 'react';
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";

import { ProjectContext } from '../util/ProjectContext'

export default function SadaBreadcrumb() {

  const { cProject } = useContext(ProjectContext);
  const location = useLocation();
  const paths = location.pathname.split('/');
  const isBrowse = paths[1] === "browse" || (paths[1] === 'projects' && !Boolean(paths[2]));

  return (
    <Breadcrumb style={{ margin: '16px 0', minHeight: "22px" }} separator=">">
      {
        isBrowse ?
          <Breadcrumb.Item key="projects_1" style={{ cursor: "pointer" }}>
            <Link to={`/projects`}> <HomeOutlined /> Projects</Link>
          </Breadcrumb.Item>
          :
          paths.map((el, index) => (index > 0 && index < paths.length - 1) && (
            <Breadcrumb.Item key={`${el}_${index}`} style={{ cursor: "pointer" }}>{
              ["projects"].indexOf(el) !== -1 ?
                <Link to={"/" + el}><HomeOutlined /> {el[0].toUpperCase() + el.substring(1)}</Link>
                : <Link to={`/projects/${cProject?.id || ''}/view`}>{cProject?.name || ''}</Link>
            }</Breadcrumb.Item>
          ))}
    </Breadcrumb>
  );
};
