import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Empty, message } from 'antd';
import { ProjectContext } from '../util/ProjectContext';
import { search } from '../services/browse.service';
import SearchBox from '../components/Browse/SearchBox.component';
import BrowsePagination from '../components/Browse/Pagination.component';
import ResultsList from '../components/Browse/ResultsList.component';
import FilesFilter from '../components/Browse/FilesFilter.component';
import Facets from '../components/Browse/Facets.component';
import CurrentFilters from '../components/Browse/CurrentFilters.component';
import { useLazyEffect } from '../util/lazy-effect.hook';
import { generateQueryString, filterFacetsByScope } from '../util/utils';

const INITIAL_RESULTS = {
    facets: {},
    list: [],
    meta: {
        pageSize: 10,
        start: 1,
        total: 0
    }
}
const BrowsePage = () => {
    const { cProject } = useContext(ProjectContext);
    const history = useHistory();
    const paramsParser = new URLSearchParams(useLocation().search);

    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useState({
        query: paramsParser.get("query") || '',
        scope: JSON.parse(paramsParser.get("scope")) || ['annotation', 'relation'],
        facets: JSON.parse(paramsParser.get("facets")) || {},
        files: JSON.parse(paramsParser.get("files")) || [],
        pageSize: parseInt(paramsParser.get("pageSize")) || 10,
        start: parseInt(paramsParser.get("start")) || 1
    });
    const [results, setResults] = useState(INITIAL_RESULTS);

    useEffect(() => {
        if (cProject?.id && (cProject.status !== "DONE"))
            history.push(window.location.pathname.replace("annotate", "upload"));
        if (cProject.id) handleSearch();
        // eslint-disable-next-line
    }, [cProject.id, cProject.status]);

    useLazyEffect(() => { handleSearch(true); }, [searchParams.query, searchParams.scope, searchParams.facets, searchParams.files]);
    useLazyEffect(() => { handleSearch(); }, [searchParams.start, searchParams.pageSize]);

    useLazyEffect(() => {
        const q = generateQueryString(searchParams);
        history.replace({
            pathname: window.location.pathname,
            search: `?${q}`
        })
    }, [searchParams]);

    const current_filters = useMemo(() => {
        const filters = Object.assign({}, results.meta.filters);
        delete filters.files;
        return filters;
    }, [results.meta.filters]);

    const handleSearch = async (resetPage) => {
        message.loading({
            content: 'Loading...',
            className: 'loading-backdrop',
            duration: 0
        });
        resetPage && setSearchParams({ ...searchParams, start: 1 });
        setLoading(true);
        const res = await search({
            q: searchParams.query,
            start: resetPage ? 1 : searchParams.start,
            pageSize: searchParams.pageSize,
            browseScope: searchParams.scope,
            projectId: cProject.id,
            filters: { ...searchParams.facets, files: searchParams.files }
        });
        if (res) {
            setResults(res);
        } else {
            setResults([]);
        }
        setLoading(false);
        message.destroy();
    }

    const handleFacetSubmit = (facet, values) => {
        if (values?.length)
            setSearchParams({ ...searchParams, facets: { ...searchParams.facets, [facet]: values } });
        else {
            const tmpF = { ...searchParams.facets };
            delete tmpF[facet];
            setSearchParams({ ...searchParams, facets: tmpF });
        }
    }

    return (
        <div>
            <Row>
                <Col span={6} style={{ padding: 10 }}>
                    <CurrentFilters
                        selectedFacets={current_filters}
                        onDelete={(facet, v) => {                           
                            handleFacetSubmit(facet, [...searchParams.facets[facet].filter(item => item !== v)]);
                        }}
                    />
                    <div style={{ marginBottom: 20 }}>
                        <h3>
                            Filters:
                        </h3>
                        <hr />
                        {
                            cProject?.id &&
                            <FilesFilter
                                onSubmit={(files) => setSearchParams({ ...searchParams, files })}
                                projectId={cProject.id}
                                selectedFiles={searchParams.files}
                                initialList={results.meta?.filters?.files || []}
                            />
                        }
                        {
                            !!results.facets &&
                            <Facets
                                facets={results.facets}
                                selectedFacets={searchParams.facets}
                                onSubmit={handleFacetSubmit}
                                loading={loading}
                                onClearAll={() => setSearchParams({ ...searchParams, facets: {} })}
                            />
                        }
                    </div>
                </Col>
                <Col span={18} style={{ padding: 10 }}>
                    <SearchBox
                        loading={loading}
                        onSubmit={(query, scope) => setSearchParams({
                            ...searchParams,
                            query,
                            scope,
                            facets: filterFacetsByScope(scope, searchParams.facets, results.facetScope)
                        })}
                        query={searchParams.query}
                        selectedScope={searchParams.scope}
                    />
                    <h3 style={{ marginBottom: 20 }}>Search Results</h3>
                    {
                        !!results.meta.total &&
                        <BrowsePagination
                            onChange={(page, pageSize) =>
                                setSearchParams({ ...searchParams, start: ((page - 1) * pageSize) + 1, pageSize })
                            }
                            total={results.meta.total}
                            pageSize={results.meta.pageSize}
                            page={((results.meta.start - 1) / results.meta.pageSize) + 1}
                        />
                    }
                    {
                        !!results.meta.total &&
                        <ResultsList
                            results={results.list}
                            scope={searchParams.scope}
                        />
                    }
                    {
                        !results.meta.total && !loading &&
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={"No Results Found!"}
                        />
                    }
                </Col>
            </Row>
        </div >
    )
};

export default BrowsePage;