import React, { useCallback } from 'react';
import PropTypes from 'prop-types'
import { Checkbox, List, Radio, Skeleton } from 'antd';

const CartridgesList = (props) => {
  const handelRadioButton = (e) => {
    props.onCartridgesChange([{ id: e.target.value, name: e.target.label }]);
  }

  const handelCheckBox = (v) => {
    const id = v.target.id || '';
    const name = v.target.label || '';
    if (v.target.checked) {
      props.onCartridgesChange([...props.selectedCartridges, { id, name }]);
    } else {
      props.onCartridgesChange(props.selectedCartridges.filter((item) => (item.id !== id)));
    }
  };

  const isChecked = useCallback((id) => !!(props.selectedCartridges.filter(v => v.id === id.toString()).length)
    , [props.selectedCartridges]);

  if (props.cartridges.length === 0)
    return <Skeleton active paragraph={{ rows: 4 }} />;

  return (
    <Radio.Group style={{ width: '100%' }} onChange={handelRadioButton} value={props.selectedCartridges?.[0]?.id || null}>
      <List
        header={props.isView ? "Selected Plan" : "Select Plan"}
        size="small"
        bordered
        rowKey="id"
        loading={!props.cartridges}
        className="files-list"
        dataSource={props.cartridges}
        renderItem={plan => (
          <List.Item>
            {
              props.isView
                ? <span>{plan.name}</span>
                : props.isMultiSelect
                  ? (
                    <Checkbox
                      id={`${plan.id}`}
                      label={plan.name}
                      onChange={handelCheckBox}
                      defaultChecked={isChecked(plan.id)}
                    >
                      {plan.name}
                    </Checkbox>
                  ) : (
                    <Radio
                      id={`${plan.id}`}
                      label={plan.name}
                      value={plan.id}
                      defaultChecked={isChecked(plan.id)}
                    >
                      {plan.name}
                    </Radio>
                  )
            }
          </List.Item>
        )}
      />
    </Radio.Group>
  );
};

CartridgesList.propTypes = {
  cartridges: PropTypes.arrayOf(Object).isRequired,
  selectedCartridges: PropTypes.arrayOf(Object),
  isView: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  onCartridgesChange: PropTypes.func
}

CartridgesList.defaultProps = {
  isView: false,
  isMultiSelect: false,
  selectedCartridges: []
}

export default CartridgesList;