let currentConfig = {};
export const loadConfig = () => {
    if (localStorage.getItem(`sada_conf_${localStorage.username}`)) {
        currentConfig = JSON.parse(localStorage.getItem(`sada_conf_${localStorage.username}`));
    }
    return currentConfig;
}

export const saveConfig = (config) => {
    localStorage.setItem(`sada_conf_${localStorage.username}`, JSON.stringify(config));
}

export const setConfig = (key, value) => {
    currentConfig[key] = value;
    saveConfig(currentConfig);
}

export const getConfig = (key) => {
    loadConfig();
    return currentConfig[key];
}