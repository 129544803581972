import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Tabs } from 'antd';
import { PartitionOutlined, TagsOutlined } from '@ant-design/icons';
import { useLazyEffect } from '../../util/lazy-effect.hook';


const { TabPane } = Tabs;

const ResultMeta = (props) => {

  const getActiveTab = () => {
    return props.scope.length > 1 ? 'mixed' : props.scope[0] || 'relation';
  }
  const [activeTab, setActiveTab] = useState(getActiveTab());
  useLazyEffect(() => { setActiveTab(getActiveTab()) }, [props.scope]);

  return (
    <Tabs activeKey={activeTab} onChange={(tab) => setActiveTab(tab)} >
      <TabPane tab="Relations" key="relation">
        {
          props.result.relations.map((ann, i) =>
            <div key={ann.id + '_' + i} className="results-meta-item">
              <Badge dot style={{ padding: 0 }} color={props.result.colors[ann.id]?.background} />
              <b>{ann.text} {ann.match && `(${ann.match})`}</b>:&nbsp;<small>{ann.type}</small>
            </div>
          )
        }
        {
          !props.result?.relations?.length && <span>No Relations in this segment!</span>
        }
      </TabPane>
      <TabPane tab="Annotations" key="annotation">
        {
          props.result.annotations.map((ann, i) =>
            <div key={ann.id + '_' + i} className="results-meta-item"><b>{ann.text} {ann.match && `(${ann.match})`}</b>: <small>{ann.type}</small></div>
          )
        }
        {
          !props.result?.annotations?.length && <span>No Annotations in this segment!</span>
        }
      </TabPane>
      <TabPane tab="Mixed" key="mixed">
        {!!props.result?.mixed?.length && <div className="results-meta-mixed-legend"><PartitionOutlined />&nbsp;Relation&nbsp;&nbsp;|&nbsp;&nbsp;<TagsOutlined />&nbsp;Annotation</div>}
        {
          props.result.mixed.map((ann, i) =>
            <div key={ann.id + '_' + i} className="results-meta-item">
              {ann.isRelation && <Badge dot style={{ padding: 0 }} color={ann.color.background} />}
              {ann.isRelation && <PartitionOutlined style={{ paddingRight: 5 }} label="Relation" />}
              {ann.isAnnoattion && <TagsOutlined style={{ paddingRight: 5 }} label="Annotation" />}
              <b>{ann.text} {ann.match && `(${ann.match})`}</b>: <small>{ann.relationType || ann.annotationType}</small>
            </div>
          )
        }
        {
          !props.result?.mixed?.length && <span>No Relations or Annotations in this segment!</span>
        }
      </TabPane>
    </Tabs >
  );
};

ResultMeta.propTypes = {
  result: PropTypes.instanceOf(Object).isRequired,
  scope: PropTypes.arrayOf(PropTypes.string).isRequired
}

ResultMeta.defaultProps = {
}

export default ResultMeta;