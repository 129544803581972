import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Tooltip, Popover, Tag } from 'antd';
import { ReloadOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import Sticky from 'react-stickynode';
import { nerTags, statusColors } from '../../data/constansts';

import ResizeTable from '../Common/ResizeTable.component';
import ValidationBar from '../Common/ValidationBar.component';
import { ProjectContext } from '../../util/ProjectContext';
import { getConfig, setConfig } from '../../util/ConfigManager';
import ColumnSelectorComponent from './ColumnSelector.comonent';
import { useLazyEffect } from '../../util/lazy-effect.hook';
import { VALIDATION_FILTERS } from '../../data/constansts';

const DEFAULT_VISIBLE_COLS = ['annotationId', 'before', 'label', 'after', 'validation_current', 'validation_prv'];
let currentGridConfig = {};

const GridValidation = (props) => {
  const [currentAnn, setCurrentAnn] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilters, setCurrentFilters] = useState({});
  const [currentSorter, setCurrentSorter] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [visibleColsKeys, setVisibleColsKeys] = useState(getConfig('visibleCols') || DEFAULT_VISIBLE_COLS);
  const { cProject } = useContext(ProjectContext);

  let columns = useMemo(() => {
    let iterations = [];
    iterations[0] = cProject.iterations[cProject.iterations.length - 1];
    iterations[1] = cProject.iterations[cProject.iterations.length - 2];
    const cols = [
      {
        title: 'Annotation ID',
        dataIndex: 'annotationId',
        key: 'annotationId',
        width: 100,
        sorter: true,
        sortLabel: 'ann-id',
        sortDirections: ['ascend', 'descend'],
        sortOrder: currentSorter.columnKey === 'annotationId' && currentSorter.order,
        fixed: 'left',
        ellipsis: {
          showTitle: false
        },
        render: (v) => <Tooltip title={v} >{v.substring(0, 20)}{v.length > 20 && '...'}</Tooltip>
      },
      {
        title: 'Before',
        dataIndex: 'before',
        key: 'before',
        ellipsis: true
      },
      {
        title: "Label",
        dataIndex: 'label',
        key: 'label',
        width: 180,
        sorter: true,
        sortLabel: 'label',
        sortDirections: ['ascend', 'descend'],
        sortOrder: currentSorter.columnKey === 'label' && currentSorter.order,
        ellipsis: true
      },
      {
        title: 'After',
        dataIndex: 'after',
        key: 'after',
        ellipsis: true
      },
      {
        title: `Validation ${iterations[0]?.num || '1'}`,
        dataIndex: ['currentIteration', 'validation'],
        key: 'validation_current',
        filters: VALIDATION_FILTERS,
        filterMultiple: true,
        filteredValue: currentFilters?.validation_current || [],
        width: 120,
        render: (v) => <Tag color={statusColors[v]}>{v}</Tag>
      },
      {
        title: 'Annotation Type',
        dataIndex: 'annotaionType',
        key: 'annotaionType',
        width: 70,
        sortLabel: 'annotaionType',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        sortOrder: currentSorter.columnKey === 'annotaionType' && currentSorter.order,
      },
      {
        title: 'Begin',
        dataIndex: 'begin',
        key: 'begin',
        width: 70,
        sortLabel: 'begin',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        sortOrder: currentSorter.columnKey === 'begin' && currentSorter.order,
      },
      {
        title: 'End',
        dataIndex: 'end',
        key: 'end',
        sortLabel: 'end',
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        sortOrder: currentSorter.columnKey === 'end' && currentSorter.order,
        width: 70,
      },
      {
        title: 'PrefLabels',
        dataIndex: 'prefLabels',
        key: 'prefLabels',
        width: 120,
        render: (v, r) => <span>{r.currentIteration?.prefLabels?.join(", ")}</span>
      },
      {
        title: 'URI',
        dataIndex: ['currentIteration', 'uri'],
        key: 'uri',
        sorter: true,
        sortLabel: 'uri',
        sortDirections: ['ascend', 'descend'],
        sortOrder: currentSorter.columnKey === 'uri' && currentSorter.order,
        width: 120,
        render: (v, r) => <Tooltip title={v} ><span>{r.currentIteration?.uri?.split('/').pop()}</span></Tooltip>
      },
    ];

    if (iterations[1]) {
      cols.splice(4, 0, {
        title: `Validation ${iterations[1].num}`,
        dataIndex: ['prevIteration', 'validation'],
        key: 'validation_prv',
        width: 120
      })
    }
    return cols;
    // eslint-disable-next-line
  }, [cProject.iterations, visibleColsKeys, currentFilters?.validation_current, currentSorter]);

  useEffect(() => {
    setCurrentFilters({});
    setCurrentSorter({});
    setCurrentAnn(0);
    setCurrentPage(1);
    setSelectedKeys([]);
    // eslint-disable-next-line
  }, [props.view, props.currentItemId]);

  useEffect(() => {
    if (props.annListMeta.start)
      setCurrentPage(((props.annListMeta.start - 1) / props.annListMeta.pageSize) + 1);
    // eslint-disable-next-line
  }, [props.annListMeta.start, props.annListMeta.pageSize]);

  useLazyEffect(() => {
    setConfig('visibleCols', visibleColsKeys);
  }, [visibleColsKeys]);

  const reloadTableData = (pagination, filters, sorter) => {
    //If this was called from the reload button
    if (pagination || filters || sorter) {
      currentGridConfig = { pagination, filters, sorter };
    }

    if (props.handelGridChange)
      props.handelGridChange(currentGridConfig.pagination, currentGridConfig.filters, currentGridConfig.sorter);

    if (pagination?.current) setCurrentPage(pagination.current);
    if (filters) setCurrentFilters(filters);
    if (sorter) setCurrentSorter(sorter);
  }

  const onSelectChange = (selectedRowKeys) => {
    setSelectedKeys(selectedRowKeys);
    if (selectedRowKeys.length > 1)
      setCurrentAnn(-1);
  };

  const handleValidation = async (id, validation, cIndex) => {
    if (id !== undefined)
      await props.validationHandler(id, validation, cIndex, false);
    else
      await props.validationHandler(selectedKeys, validation, -1, true);

    if (currentFilters)
      reloadTableData();
    setSelectedKeys([]);
    setCurrentAnn(-1);
  }

  const handleKeyDown = (e) => {
    if (e.altKey || e.ctrlKey || e.shiftKey)
      return;

    const ann = props.annList[currentAnn];
    if (!ann?.id) return;
    switch (e.keyCode) {
      case 39: //next
      case 40: //down
        e.stopPropagation();
        e.preventDefault();
        nextAnnotation();
        break;
      case 37: //prev
      case 38: //up
        e.stopPropagation();
        e.preventDefault();
        prevAnnotation();
        break;
      case 90: //z
        if (ann.validation?.value === "TO-VALIDATE")
          handleValidation(ann.id, "CORRECT-TYPE", currentAnn);
        break;
      case 88: //x
        if (ann.validation?.value === "TO-VALIDATE")
          handleValidation(ann.id, "INCORRECT", currentAnn);
        break;
      case 67: //c
        if (ann.validation?.value === "TO-VALIDATE")
          handleValidation(ann.id, "CORRECT", currentAnn);
        break;
      case 86: //v
        if (ann.validation?.value !== "TO-VALIDATE")
          handleValidation(ann.id, "TO-VALIDATE", currentAnn);
        break;
      default:
        break;
    }
  }

  const nextAnnotation = () => {
    const isLastPage = (currentPage >= props.annListMeta.total / props.annListMeta.pageSize);
    const isLastItem = isLastPage && (currentAnn + 1 >= (props.annListMeta.pageSize - ((Math.ceil(props.annListMeta.total / props.annListMeta.pageSize) * props.annListMeta.pageSize) - props.annListMeta.total)));
    if (isLastItem || (currentAnn + 1 >= (props.annListMeta.pageSize))) return;
    setCurrentAnn(currentAnn + 1);
  }

  const prevAnnotation = () => {
    const isFirstItem = (currentAnn <= 0);
    if (isFirstItem) return;
    setCurrentAnn(currentAnn - 1);
  }

  const handelSelectedColChange = (e) => {
    if (e.target.checked)
      setVisibleColsKeys([...visibleColsKeys.filter(el => el !== e.target.value), e.target.value]);
    else
      setVisibleColsKeys([...visibleColsKeys.filter(el => el !== e.target.value)]);
  }

  const annotation = props.annList[currentAnn];

  const TableTitle = () => (
    <div >
      <Tooltip title="Show/ Hide Columns">
        <Popover trigger="click" content={
          <ColumnSelectorComponent
            columns={columns}
            visibleColsKeys={visibleColsKeys}
            onChange={handelSelectedColChange} />}>
          <Button
            icon={<UnorderedListOutlined />}
            shape="circle"
          >
          </Button>
        </Popover>
      </Tooltip>
      <Tooltip title="Reload Data">
        <Button
          onClick={() => reloadTableData()}
          icon={<ReloadOutlined />}
          shape="circle"
        >
        </Button>
      </Tooltip>
      <Sticky className="StickyValidationBar-Outer" style={{ display: 'inline-block', background: 'red' }} enabled={true} top={100} innerZ={1005} activeClass="StickyAnnotateHeader-Active" innerClass="StickyValidationBar" >
        <div className={(selectedKeys.length ? '' : 'disabled')}>
          <ValidationBar annotationId={annotation?.id || undefined} handler={handleValidation} currentAnn={currentAnn} />
        </div>
      </Sticky>
    </div>
  )

  return (
    <div
      tabIndex="0"
      style={{ outline: 0 }}
      onKeyDown={handleKeyDown}>
      <Row gutter={[8, 8]}>
        <Col span={8}>

          {
            !!annotation?.annotaionType && <span>
              <b>Annotation Type:</b>&nbsp;
              <Tooltip
                overlay={<div>{nerTags[annotation?.annotaionType]?.label || ''} <br />
                  i.e. {nerTags[annotation?.annotaionType]?.example || ''}
                </div>}
                overlayStyle={{ maxWidth: 'unset' }}
              >
                {annotation?.annotaionType}
              </Tooltip>
            </span>
          }
        </Col>
        <Col span={8}>
          {annotation?.filename && props.view === 'annotationType' &&
            <span><b>File Name:</b> {annotation?.filename}</span>
          }
        </Col>
        {/* <Col span={4}>
                    {annotation?.currentIteration?.language &&
                        <span><b>Language:</b> {annotation?.currentIteration?.language}</span>
                    }
                </Col> */}
        {/* <Col span={4}>
                    {annotation?.currentIteration?.syncon &&
                        <span><b>SynconID:</b> {annotation?.currentIteration?.syncon}</span>
                    }
                </Col> */}
        <Col span={8} style={{ textAlign: "right" }}>
          <Button style={{ width: "120px" }} title="Previous annotation (left Key)" onClick={prevAnnotation} disabled={(currentAnn - 1) < 0}><LeftOutlined /> Previous</Button> &nbsp;
          <Button style={{ width: "120px" }} title="Next annotation (Right Key)" onClick={nextAnnotation} disabled={(currentAnn + 1) >= props.annListMeta.pageSize}>Next <RightOutlined /></Button>
        </Col>
      </Row>
      <Row style={{ height: "3.5em" }} gutter={[15, 8]} >
        <Col span={8} >
          <b>Before:</b> {!!annotation && annotation.before}
        </Col>
        <Col span={4}>
          <b>Label:</b> {!!annotation && annotation.label}
        </Col>
        <Col span={8}>
          <b>After:</b> {!!annotation && annotation.after}
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <ResizeTable
            size="small"
            bordered
            className="grid-view-table"
            rowKey="id"
            showSorterTooltip={false}
            style={{ cursor: 'pointer' }}
            title={TableTitle}
            rowSelection={{
              columnWidth: 30,
              hideSelectAll: false,
              onChange: onSelectChange,
              selectedRowKeys: selectedKeys,
            }}
            onRow={(record, index) => ({
              onClick: () => {
                setCurrentAnn(index);
                if (selectedKeys.length && selectedKeys.indexOf(record.id) === -1)
                  setSelectedKeys([...selectedKeys, record.id])
              },
            })}
            scroll={{ x: 1000 }}
            scrollToFirstRowOnChange={true}
            dataSource={props.annList}
            pagination={{
              showQuickJumper: true,
              position: ["topRight", "none"],
              pageSize: props.annListMeta.pageSize,
              total: props.annListMeta.total,
              showSizeChanger: true,
              current: currentPage,
              className: "grid-validation-pagination"
            }}
            onChange={reloadTableData}
            columns={[...columns.filter(col => visibleColsKeys?.includes(col.key)), {
              title: 'Actions',
              key: 'actions',
              render: (v) => (props.annList[currentAnn]?.id === v.id ?
                <ValidationBar
                  icons
                  size="medium"
                  annotationId={v.id}
                  handler={handleValidation}
                  currentAnn={currentAnn}
                  isEdit={props.annList[currentAnn]?.validation?.value !== "TO-VALIDATE"} />
                : ""),
              fixed: "right",
              width: 180,
            }]} />
        </Col>
      </Row>
    </div>
  )
};

GridValidation.propTypes = {
  view: PropTypes.string.isRequired,
  annList: PropTypes.arrayOf(Object),
  annListMeta: PropTypes.instanceOf(Object),
  validationHandler: PropTypes.func,
  handelGridChange: PropTypes.func,
}

GridValidation.defaultProps = {
  annList: [],
  annListMeta: {
    pageSize: 10,
    start: 1,
    total: 0
  },
  validationHandler: undefined,
  handelGridChange: undefined
}

export default GridValidation;